import React, { useEffect, useState, Fragment } from "react";
import useSound from "use-sound";
import correct from "../asset/sounds/Correct Answer Sound Effect.mp3";
import wrong from "../asset/sounds/Wrong Answer sound effect.mp3";
import '../App.css';
import GuessesLeft from "./GuessesLeft";
import { isMobile } from "react-device-detect";
import Clue from '../../../components/Clue';
import imgCorrect from '../../../assets/images/common-images/correct.png';
import imgWrong from '../../../assets/images/common-images/wrong.png';
import { ids } from "../../../constants";
import { Keyboard } from "../../../Keyboard";
import Word from '../../../components/Word';
import Pay from '../../../assets/images/common-images/pay.png';
import PayPrompt from '../../../assets/images/common-images/pay_prompt.png';
import { coinUse } from "../../../services/ApiService";

const Questionnaire = ({
    data: { setting, questions },
    handleAnswer,
    done,
    prizes,
    gameType,
    keyboardLayout,
    channel
}) => {
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [className, setClassName] = useState("phanswer");
    const [correctAnswer] = useSound(correct);
    const [wrongAnswer] = useSound(wrong);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [correct_answer, setCorrectAnswer] = useState("");
    const [answers, setAnswers] = useState([]);
    const [question, setQuestion] = useState("");
    const [guessesLeft, setGuessesLeft] = useState(6);
    const [showPrize, setShowPrize] = useState(false);
    const [selectedPrize, setSelectedPrize] = useState();
    const [outcome, setOutcome] = useState("");
    const [outcomeColor, setOutcomeColor] = useState('white');
    const [outcomeImage, setOutcomeImage] = useState();
    const [showOutcomeImage, setShowOutcomeImage] = useState(false);
    const [isFinalQuestion, setIsFinalQuestion] = useState(false);
    const [notPaid, setNotPaid] = useState(true);
    const questionsSet = questions.map((question) => ({
        ...question,
        answers: [
          question.correct_answer,
          ...question.incorrect_answers,
        ].sort(() => Math.random() - 0.5)
    }))
    const [showPayPrompt, setShowPayPrompt] = useState(false);
    const [value, setValue] = useState("");
    const [clues] = useState([]);
    let letterInfo = new Map();

    useEffect(() => {
        setClassName("phanswer");
    }, [])
    // console.log(`questions=${JSON.stringify(questions)}`)

    useEffect(() => {
        if (currentIndex < questionsSet.length) {
            // console.log(`here=${JSON.stringify(questionsSet)}; currentIndex=${currentIndex}`)
            const qs = questionsSet[currentIndex];
            // console.log(`here=${JSON.stringify(qs)}`)
            setCorrectAnswer(qs.correct_answer);
            setAnswers(qs.answers);
            setIsFinalQuestion(currentIndex == questionsSet.length - 1)
        }
    }, [currentIndex])

    const delay = (duration, callback) => {
        setTimeout(() => {
            callback();
        }, duration);
    };

    const handleClick = (answer) => {
        if (!selectedAnswer) {
            setSelectedAnswer(answer);
            setClassName("phanswer");
            // delay(500, () => {
                setClassName(answer === correct_answer ? "phanswer correct" : "phanswer wrong");
            // });
            if (currentIndex < questionsSet.length - 1) {
                setQuestion(`${question}<br />${correct_answer}`);
                clues.push({
                    clue: correct_answer,
                    isCorrect: answer === correct_answer
                })
            }
            // setOutcome(answer === correct_answer ? "You're right" : 'Not quite')
            setOutcomeImage(answer == correct_answer ? imgCorrect : imgWrong);
            setShowOutcomeImage(true);
            // setOutcomeColor(answer === correct_answer ? '#3fff3f' : 'red')
            setOutcomeColor('white')
            delay(100, () => {
                if (answer === correct_answer) {
                    correctAnswer();
                    if (currentIndex == questionsSet.length - 1) {
                        done(true);
                    }
                    delay(3000, () => {
                        setSelectedAnswer(null);
                        setOutcomeImage(null);
                        setShowOutcomeImage(false);
                        setOutcome("");
                        setOutcomeColor('white')
                        setCurrentIndex(currentIndex + 1);
                        // console.log(`currentIndex=${currentIndex}; questionsSet.length=${questionsSet.length}`)
                        if (currentIndex == questionsSet.length - 1) {
                            handleAnswer(correct_answer, answer, guessesLeft);
                        }
                    });

                } else {
                    wrongAnswer();
                    if (currentIndex == questionsSet.length - 1) {
                        done(true);
                    }
                    else {
                        setGuessesLeft(guessesLeft - 1);
                    }
                    delay(3000, () => {
                        setSelectedAnswer(null);
                        setOutcomeImage(null);
                        setShowOutcomeImage(false);
                        setOutcome("");
                        setOutcomeColor('white');
                        setCurrentIndex(currentIndex + 1);
                        // console.log(`currentIndex=${currentIndex}; questionsSet.length=${questionsSet.length}`)
                        if (currentIndex == questionsSet.length - 1) {
                            handleAnswer(correct_answer, answer, guessesLeft);
                        }
                    });


                }

            })
        }
    };

    const prizeClick = (i: number) => {
        // console.log('here')
        setShowPrize(true);
        setSelectedPrize(i);
    }

    const onKey = (key: string) => {
        // console.log(`correct_answer=${correct_answer}; value=${value}; guessLeft=${guessesLeft}`)
        if (guessesLeft > 0) {
            if (/^[-a-z]$/i.test(key)) {
                if (correct_answer.split('').length === value.split('').length) {
                    //do nothing
                }
                else if (correct_answer.split('')[value.length] == " ") {
                    setValue(value + " " + key.toUpperCase());
                }
                else {
                    setValue(value + key.toUpperCase());
                }
            } else if (key === "Backspace") {
                if (correct_answer.split('')[value.length - 1] == " ") {
                    setValue(value.slice(0, -2));
                }
                else {
                    setValue(value.slice(0, -1));
                }
            } else if (key === "Enter") {
                delay(100, () => {
                    // console.log(`value=${value}, correct_answer=${correct_answer}`)
                    setOutcomeImage(value.toUpperCase() == correct_answer.toUpperCase() ? imgCorrect : imgWrong);
                    setShowOutcomeImage(true);
                    if (value.toUpperCase() == correct_answer.toUpperCase() && value.split('').length > 0) {
                        correctAnswer();
                        done(true);
                        delay(3000, () => {
                            setSelectedAnswer(null);
                            setOutcomeImage(null);
                            setShowOutcomeImage(false);
                            setOutcome("");
                            setOutcomeColor('white')
                            setCurrentIndex(currentIndex + 1);
                            handleAnswer(correct_answer.toUpperCase(), value.toUpperCase(), guessesLeft);
                        });
                    } else {
                        wrongAnswer();
                        if (guessesLeft == 1) {
                            done(true);
                            delay(3000, () => {
                                handleAnswer(correct_answer.toUpperCase(), value.toUpperCase(), guessesLeft);
                            })
                        }
                        delay(3000, () => {
                            setOutcomeImage(null);
                            setShowOutcomeImage(false);
                        });
                        setGuessesLeft(guessesLeft - 1);
                        setSelectedAnswer(null);
                        setOutcome("");
                        setValue("");
                        setOutcomeColor('white');
                    }        
                })
            }
        }
    }

    // useEffect(() => {
    //     const onKeyDown = (e: KeyboardEvent) => {
    //         console.log(`e.key=${e.key}`)
    //       if (!e.ctrlKey && !e.metaKey) {
    //         onKey(e.key);
    //       }
    //       if (e.key === "Backspace") {
    //         e.preventDefault();
    //       }
    //     };
    //     document.addEventListener("keydown", onKeyDown);
    //     return () => {
    //       document.removeEventListener("keydown", onKeyDown);
    //     };
    // }, []);

    const onPayClick = async () => {
        setShowPayPrompt(true);
        setNotPaid(false);
        done(true);
        const coinParam = {
            "transactionType": ids.txnTypeRondleTopicFee,
            "description": `Hint fee in ${channel} Channel`,
            "amount": -100,
            "deviceBalance": 0,
            "serverBalance": 0
        }
        await coinUse(coinParam)
    }

    return (
        // Questionnaire main container
        <div className="questionnaire">

            {/* question container */}
            {/* <h2 className="clues">Clues</h2> */}
            {/* <div className="question1 row">
                {clues.map((clue) => <span className="clue" style={{color: clue.isCorrect ? '#3fff3f' : 'white'}}>{clue.clue}<br /></span>)}
            </div> */}
            <Clue setting={setting} category={clues} scale={gameType === ids.gameTypePinoyHenyo2 && isFinalQuestion ? 0.65 : 1}/>
            <div className="guessfooter" style={{top: gameType === ids.gameTypePinoyHenyo2 && isFinalQuestion? '52%' : isMobile ? '67%' : '60%'}}>
                <GuessesLeft guessesLeft={guessesLeft} prizes={prizes} prizeClick={prizeClick} />
                {gameType === ids.gameTypePinoyHenyo2 && isFinalQuestion && notPaid && <div onClick={onPayClick}>
                    <img src={Pay} className="payIcon"></img>
                </div>}
            </div>

            {/* answers container */}
            {gameType === ids.gameTypePinoyHenyo && answers.length > 2  && <h3 className="phoutcome" style={{color: outcomeColor}}>{Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypeDobleKahol ? 'Pumili ng isa' : 'Choose one'}</h3>}
            {(gameType === ids.gameTypePinoyHenyo || gameType === ids.gameTypePinoyHenyo2 && isFinalQuestion && !notPaid) && <div className='answers row' style={{top: answers.length == 2 ? '75%' : '79%'}}>
                    {answers.length == 2 && <p className='phor'>{Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypeDobleKahol ? 'o' : 'or'}</p>}
                    {answers.map((answer, idx) => {
                        return (
                            <button
                                key={idx}
                                dangerouslySetInnerHTML={{ __html: answer }}
                                className={selectedAnswer === answer ? className : "phanswer"}
                                onClick={() => !selectedAnswer && handleClick(answer)}
                                style={{width: '80%', bottom: answers.length == 2 ? '0px' : '40px',
                                    fontSize: answers.length == 2 ? '20px' : '16px',
                                    marginBottom: answers.length == 2 ? "40px" : "5px",
                                    padding: answers.length == 4 ? '4px' : '10px'
                                }}
                            />
                        );
                    })}
                </div>
            }
            {gameType === ids.gameTypePinoyHenyo2 && answers.length == 2 && <div className='answers row' style={{top: '75%'}}>
                    <p className='phor'>{Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypeDobleKahol ? 'o' : 'or'}</p>
                    {answers.map((answer, idx) => {
                        return (
                            <button
                                key={idx}
                                dangerouslySetInnerHTML={{ __html: answer }}
                                className={selectedAnswer === answer ? className : "phanswer"}
                                onClick={() => !selectedAnswer && handleClick(answer)}
                                style={{width: '80%', bottom: '0px',
                                    fontSize: '20px',
                                    marginBottom: "40px"
                                }}
                            />
                        );
                    })}
                </div>
            }
            {gameType === ids.gameTypePinoyHenyo2 && isFinalQuestion && notPaid && <div className='answers row' style={{top: '55%'}}>
                    {/* {[...Array(correct_answer.split('').length)].map((e: any, i: number) => 
                        <>
                            <div style={{width: 36, height: 36, marginRight: 2, backgroundColor:  correct_answer.split('')[i] == " " ? 'transparent' : 'red', 
                                marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', 
                                border: correct_answer.split('')[i] == " " ? "" : '1px solid white',
                                color: 'white', fontWeight: '600', fontSize: '18px', paddingBottom: '2px'}}
                            >
                                    
                                {value.toUpperCase().split('').length > i && value.split('')[i]}
                            </div>
                            {correct_answer.split('')[i] == " " && <div style={{width: `${(10-i)*10}%`}}/>}
                        </>
                    )} */}
                    {/* <Fragment> */}
                    <Word
                        word={correct_answer.toUpperCase().split('').map((e: string, i: number) => {
                            // console.log(`e=${e}; i=${i}; value=${value}`)
                            return {
                                "letter": e == " " ? e : value.length > i ? value[i] : e, 
                                "disabled": value.length > i
                            }
                        })}
                        guessedTheWord={correct_answer.toUpperCase() == value.toUpperCase()}
                    />
                    {/* </Fragment> */}
                    <Keyboard
                        layout={keyboardLayout}
                        letterInfo={letterInfo}
                        onKey={onKey}
                    />
                </div>
            }
            {/* {currentIndex < questionsSet.length - 1 && <h3 className="phchoose">Choose one</h3>} */}
            {showPrize && (
                <div
                    className="tutorialContainer"
                    onClick={() => setShowPrize(false)}
                >
                    <img src={prizes[selectedPrize].imageBig} className="tutorialImage" />
                    <p className="close">X</p>
                </div>
            )}
            {showOutcomeImage && <img src={outcomeImage} style={{zIndex: 100, top: gameType === ids.gameTypePinoyHenyo2 && 
                isFinalQuestion? '22%' : isMobile ? '26%' : '22%', position: 'absolute'}}/>}
            <div className={`bg_modal_background ${showPayPrompt && 'modal_bg_show'}`}>
                <div className={`bg_modal ${showPayPrompt && 'modal_show'}`}
                    onClick={() => {
                        setShowPayPrompt(false)
                        done(false)
                    }}
                >
                    <img src={PayPrompt} className="tutorialImage" />
                </div>
            </div>
        </div>
    );
};

export default Questionnaire;