import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pageDetailContainer: {
    display: "flex",
    alignItems: "center",
    padding: 5,
    height: 95
  },
  pageDetailInfoContainer: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    color: "white",
  },
  inviteImage: {
    width: 80,
    borderRadius: 40,
  },
  inviteInfo: {
    textAlign: "left",
    maxWidth: 180,
    fontFamily: 'canaroBook',
    fontSize: '13px'
  },
  timeValue: {
    paddingLeft: 10,
  },
  scoreCounter: {
    position: 'absolute',
    width: '150px',
    right: 0,
    marginLeft: 10,
    zIndex: 1,
    transform: 'scale(60%)'
  },
}));
