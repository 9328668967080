import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  detailBrandContainer: {       
    color: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    borderBottom: '1px solid',
    display: 'flex',
    paddingTop: 20,
    height: 200,
    alignItems: 'center',
    flexDirection: 'column'
  },
  inviteImage: {
    marginTop: 20,
    width: 70,
    height: 70,
  },
  displayBrandData: {
    color: '#bbb3b3',
    textAlign: 'center'
  },
  detailBrandTitle: {
    textAlign: 'center'
  }
}));
