import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import useWindowDimensions from "../../utils/windowDimention";
import profile from "../../assets/images/common-images/user_placeholder.png";
import Avatar from "@mui/material/Avatar";
// import WebFont from 'webfontloader';
import { border } from "@mui/system";
// import Volume from "./Volumbtn"
import InnerPageHeader from "../../components/InnerPageHeader";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo/tourney_logo.png";
import moment from "moment";
import {
  formatNumberWithComma,
  msToShortenedTimeLeft,
  getTimeLeft,
} from "../../utils/utils";

import { getLeaderboard } from "../../services/ApiService";
import { routes } from "../../constants";

const Users = [
  {
    no: 4,
    profile: <Avatar sx={{ width: 30, height: 30 }} />,
    usercode: "RG05688",
    userid: 304,
  },
  {
    no: 5,
    profile: <Avatar sx={{ width: 30, height: 30 }} />,
    usercode: "RG05688",
    userid: 304,
  },
  {
    no: 6,
    profile: <Avatar sx={{ width: 30, height: 30 }} />,
    usercode: "RG05688",
    userid: 304,
  },
  {
    no: 7,
    profile: <Avatar sx={{ width: 30, height: 30 }} />,
    usercode: "RG05688",
    userid: 304,
  },
  {
    no: 8,
    profile: <Avatar sx={{ width: 30, height: 30 }} />,
    usercode: "RG05688",
    userid: 304,
  },
  {
    no: 9,
    profile: <Avatar sx={{ width: 30, height: 30 }} />,
    usercode: "RG05688",
    userid: 304,
  },
  {
    no: 10,
    profile: <Avatar sx={{ width: 30, height: 30 }} />,
    usercode: "RG05688",
    userid: 304,
  },
];

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const NUM_ITEMS = 1;

const Leaderboard = () => {
  // useEffect(() => {
  //     addFont()
  // }, []);

  // const addFont = () => {
  //     WebFont.load({
  //         google: {
  //             families: ['Droid Sans', 'Chilanka']
  //         }
  //     });
  // }

  // function push() {
  //   navigate(-1);
  // }

  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [progress, setProgress] = useState(0);
  const props = useLocation().state;
  const { engagementData } = props;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [rank1Image, setRank1Image] = useState();
  const [rank1Name, setRank1Name] = useState();
  const [rank1Score, setRank1Score] = useState();
  const [rank2Image, setRank2Image] = useState();
  const [rank2Name, setRank2Name] = useState();
  const [rank2Score, setRank2Score] = useState();
  const [rank3Image, setRank3Image] = useState();
  const [rank3Name, setRank3Name] = useState();
  const [rank3Score, setRank3Score] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeElapsed(timeElapsed + 1);
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    fetchData();
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: true });
    return () => {
      window.scrollTo(0, 1);
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchmove", preventTouch, {
        passive: false,
      });
    };
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home);
    };
  }, []);

  const fetchData = async () => {
    let numItems = NUM_ITEMS;
    if (
      typeof engagementData.topPlayers !== "undefined" &&
      engagementData.topPlayers > 0
    ) {
      numItems = Math.max(engagementData.topPlayers, NUM_ITEMS);
    }
    // console.log(`numItems=${numItems}; topPlayers=${engagementData.topPlayers}`)
    try {
      const response = await getLeaderboard({
        numItems,
        engagementId: engagementData.newId,
      });
      if (response) {
        const data = response.data;
        if (data.length > 0) {
          setRank1Image(data[0].avatarBig);
          setRank1Name(data[0].displayName);
          setRank1Score(data[0].bestScore);
        }
        if (data.length > 1) {
          setRank2Image(data[1].avatarBig);
          setRank2Name(data[1].displayName);
          setRank2Score(data[1].bestScore);
        }
        if (data.length > 2) {
          setRank3Image(data[2].avatarBig);
          setRank3Name(data[2].displayName);
          setRank3Score(data[2].bestScore);
        }
        setData(data.slice(3, numItems));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    for (let index = 1; index <= 100; index++) {
      setTimeout(() => {
        setProgress(index);
        if (index === 100) {
          // var timer = setTimeout(() => {
          //   navigate(routes.funtyperunner, { state: props });
          // }, 3500);
          // i++;
          // return () => {
          //   clearTimeout(timer)
          // }
        }
      }, 1000);
    }
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  return (
    <div className={classes.homeContainer} style={{ height: height }}>
      <div style={{ paddingLeft: -20, paddingRight: 20 }}>
        <InnerPageHeader
          hasBackBtn
          // hasSoundBtn
          goBackClick={() => {
            navigate(-1);
          }}
          title={`${process.env.REACT_APP_PREFIX}: ${engagementData.title}`}
        />
      </div>
      <div className={classes.giftBoxContent}>
        <div className={classes.giftBoxSmallImageBox}>
          <img src={Logo} className={classes.giftBoxSmallImage} />
        </div>
        <div className={classes.giftBoxImageVideoContent}>
          <h1 className={classes.leadhead}>LEADERBOARD</h1>
          <h3 className={classes.date}>
            ends in{" "}
            {msToShortenedTimeLeft(
              getTimeLeft(engagementData.joinEndDate) - timeElapsed,
              "Expired"
            )}
          </h3>
          <div className={classes.outer_main}>
            <div className={classes.innerleft}>
              <h3 className={classes.innerhead}>Rank 2</h3>
              <div className={classes.rounded}>
                <img
                  src={rank2Image ? rank2Image : profile}
                  className={classes.imgrounded}
                />
              </div>
              <div className={classes.name}>{rank2Name}</div>
              <div className={classes.user}>
                {rank2Score ? formatNumberWithComma(rank2Score, 0) : ""}
              </div>
            </div>

            <div className={classes.middlebord}>
              <h3 className={classes.innerhead}>Rank 1</h3>
              <div>
                <div className={classes.rounded_mid}>
                  <img
                    src={rank1Image ? rank1Image : profile}
                    className={classes.imgrounded}
                  />
                </div>
              </div>
              <div className={classes.name}>{rank1Name}</div>
              <div className={classes.user}>
                {rank1Score ? formatNumberWithComma(rank1Score, 0) : ""}
              </div>
            </div>

            <div className={classes.innerright}>
              <h3 className={classes.innerhead}>Rank 3</h3>
              <div className={classes.rounded}>
                <img
                  src={rank3Image ? rank3Image : profile}
                  className={classes.imgrounded}
                />
              </div>
              <div className={classes.name}>{rank3Name}</div>
              <div className={classes.user}>
                {rank3Score ? formatNumberWithComma(rank3Score, 0) : ""}
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "0px 10px 10px 10px",
              marginTop: 5,
              minHeight: 300,
            }}
          >
            {data.map((e, index) => {
              return (
                <div key={`profile-1${index}`} className={classes.list}>
                  <div className={classes.Nom}>
                    <p className={classes.number}>{e.rank}</p>
                  </div>
                  <img
                    src={e.avatarBig ? e.avatarBig : profile}
                    className={classes.avt}
                  />
                  <div className={classes.usercode}>
                    <p className={classes.para}>{e.displayName}</p>
                  </div>
                  <div className={classes.userid}>
                    <p>{formatNumberWithComma(e.bestScore, 0)}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
