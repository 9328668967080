import * as React from "react";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        position: 'absolute',
        flexDirection: 'column',
        left: 0, top: 0, right: 0, bottom: 0
      }}
    >
      {/* <img src={Logo} style={{width: 160, height: 160}} /> */}
      <lottie-player
        // src="https://assets4.lottiefiles.com/private_files/lf30_MK1ZRw.json"
        src="https://assets7.lottiefiles.com/private_files/lf30_lndg7fhf.json"
        background="transparent"
        speed="1"
        style={{ width: "100px", height: "100px", marginTop: 50 }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
};

export default Loader;
