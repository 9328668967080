import "./styles.css";
import TopicGame from "./containers/App";
import { useEffect, useRef, useState } from "react";
import InnerPageHeader from "../../components/InnerPageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { coinUse, getWinEnggR2New, payOneTimeFee, addToJoin, addFamer, completeR2Win2, processRondleSteal } from "../../services/ApiService";
import Logo from '../../assets/images/wordle.png'
import useStyles from './style';
import Coin from '../../assets/images/header-images/Coin.png';
import { isIOS, isMobile } from "react-device-detect";
import { ids, routes } from "../../constants";
import HeartOn from '../../assets/images/common-images/heart_on.png';
import HeartOff from '../../assets/images/common-images/heart_off.png';
import Postloader from "../Postloader";
import MaxJoinTopic from '../../assets/images/common-images/max_joins_topic.png';

enum GameState {
  Playing,
  Won,
  Lost,
}

function App() {
  const navigate = useNavigate();
  const props = useLocation().state;
  
  const [gameState, setGameState] = useState(GameState.Playing);
  const [hofWinData, setHofWinData] = useState({
    amount: 0,
    product: null
  });
  // const [hofWinData, setHofWinData] = useState({
  //   amount: 0,
  //   product: {
  //     "id": 20021,
  //     "name": "1 EGLD",
  //     "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648799030951.png"
  //   }
  // })
  const [r2, setR2] = useState({amount: -1});
  const [sequence, setSequence] = useState(1);
  const [word, setWord] = useState('');
  const [guessLeft, setGuessLeft] = useState(0);
  const [winComparisonId, setWinComparisonId] = useState();
  const [wins, setWins] = useState(props.joinSummary.length > 0 ? props.joinSummary[0].wins : 0);
  const [showMaxJoin, setShowMaxJoin] = useState(false);
  const [questionId, setQuestionId] = useState();

  const classes = useStyles();

  // useEffect(() => {
  //   fetchR2();
  //   fetchSequence();
  // }, [])

  // const fetchSequence = async () => {
  //   try {
  //     const response = await getWinEnggR2Sequence({engagementId: props.engagementData.newId});
  //     //  console.log(`response=${JSON.stringify(response)}`)
  //     if (response && response.data.sequence) {
  //       setSequence(response.data.sequence + 1);
  //     }
  //   } catch (e) {
  //     console.log(`error=${e}`);
  //   }
  // };

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home);
    };
  }, []);

  const fetchR2 = async (guesses: number, word: string) => {
    try {
      const response = await getWinEnggR2New({engagementId: props.engagementData.newId, score: guesses, word: encodeURIComponent(word)});
      //  console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setR2(response.data);
        setWinComparisonId(response.data.winComparisonId);
      }
    } catch (e) {
      console.log(`error=${e}`);
    }
  };

  useEffect(() => {
    const wonData = r2;
    // console.log(`wonData=${JSON.stringify(wonData)}`)
    setHofWinData({
      amount:  wonData.amount,
      product: wonData.product
    })
  }, [r2])

  const _addFamer = async () => {
    try {
      await addFamer({ engagementId: props.engagementData.newId })
    } catch (e) {
      console.log(e);
    }
  };

  const uploadJoin = async (isWon) => {
    try {
      const joinData = [
        {
          engagement_id: props.engagementData.newId,
          engagement_title: props.engagementData.title,
          record_time: new Date().toISOString().slice(0, -5),
          win_criteria: isWon ? 0 : 1,
          amount: 0,
          tickets: 0,
          difficulty_level: props.userData.difficultyClassId,
          type: 2 //words - 1; topic - 2; numbers - 3; 
        },
      ];
      await addToJoin(joinData);
      // console.log("response", response);
    } catch (e) {
      console.log(e);
    }
  }

  const processWin = async () => {
    // console.log(`r2=${JSON.stringify(r2)}, guesses=${guesses}`)
    // const wonData = r2[guesses]; 
    
    // try {
    //   await completeR2Win({
    //     engagementId: props.engagementData.newId,
    //     win: guesses + 1, //+1 cause 0-based array
    //     sequence,
    //     word: word,
    //     txnType: ids.txnTypeRondleTopicWin
    // });
    // } catch (e) {
    //   console.log(e);
    // }
    try {
      await completeR2Win2({
        winComparisonId: winComparisonId,
        txnType: ids.txnTypeRondleTopicWin
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (winComparisonId) {
      processWin()
    }
  }, [winComparisonId])

  const notifyWon = async (guesses: number, word: string) => {
    if (props.fromNotification) {
      setHofWinData({
        amount:  50,
        product: null
      })
      try {
        await processRondleSteal({ stealId: props.stealId, winnerId: props.defenderId })
      } catch (e) {
        console.log(e);
      }
      setTimeout(() => {
        setGameState(GameState.Won);    
        setGuessLeft(6-guesses);
      }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
    }
    else {
      await fetchR2(6-guesses, word);
      setTimeout(() => {
        setGameState(GameState.Won);    
        setGuessLeft(6-guesses);
        setWins(wins + 1);
        uploadJoin(true);
        if (guesses == 0) { //only guessed no lives used will be added to famer
          _addFamer();
        }
      }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
    }
  }

  const notifyLost = (word: string) => {
    console.log(`props.stealId=${props.stealId}, props.challengerId=${props.challengerId}, props.defenderId=${props.defenderId}`)
    setWord(word);
    setGameState(GameState.Lost);
    if (props.fromNotification) {
      try {
        processRondleSteal({ stealId: props.stealId, winnerId: props.challengerId })
      } catch (e) {
        console.log(e);
      }
    }
    else {
      uploadJoin(false);
    }
  }

  const onQuitPress = () => {
    setGameState(GameState.Lost);
    uploadJoin(false);
  }

  const onRepeatPress = () => {
    if (wins >= ids.MaxWinsTopic) {
      setShowMaxJoin(true);
    }
    else {
      setGameState(GameState.Playing);
      if (gameRef) {
        gameRef.current?.startNewGame();
      }
    }
  }

  const gameRef = useRef();

  useEffect(() => {
    document.body.classList.add("not-scrollable");
  
    // returned function will be called on component unmount 
    return () => {
      document.body.classList.remove("not-scrollable");
    }
  }, [])

  const joinRequirement = (JSON.parse(props.engagementData.joinRequirement) || []).filter(j => j.type == 4)[0];

  return (
    <div className={"Topic-container"} style={{backgroundColor: 'orange'}}>
      {/* <h3 className="Title">
        Guess the Topic
      </h3> */}
      <TopicGame
        // prizeImage={props.engagementData.tutorialUrl}
        // joinFee={props.engagementData.joinFee}
        // newId={props.engagementData.newId}
        // title={props.engagementData.title}
        // difficultyClassId={props.userData.difficultyClassId}
        background={joinRequirement.background}
        prizes={joinRequirement.prizes}
        notifyWon={(guesses: number, word: string) => notifyWon(guesses, word)}
        notifyLost={(word: string) => notifyLost(word)}
        setWord={(word: string) => setWord(word)}
        setQuestionId={(questionId: number) => setQuestionId(questionId)}
        fromNotification={props.fromNotification}
        questionId={props.questionId}
        wins={wins}
        set1={(props.userData.classId === ids.classA || props.userData.classId === ids.classB) ? joinRequirement.set1H : joinRequirement.set1E}
        set2={(props.userData.classId === ids.classA || props.userData.classId === ids.classB) ? joinRequirement.set2H : joinRequirement.set2E}
        set3={(props.userData.classId === ids.classA || props.userData.classId === ids.classB) ? joinRequirement.set3H : joinRequirement.set3E}
        ref={gameRef}
      />
      {!showMaxJoin && <Postloader gameState={gameState} hofWinData={hofWinData} guessLeft={guessLeft} correctText='CORRECT ANSWER:' 
        word={word.toUpperCase()} questionId={questionId}
        avatarBig={props.engagementData.source.avatarBig} postloaderImageBig={props.engagementData.postloaderImageBig} 
        sourceId={props.engagementData.source.id} engagementId={props.engagementData.newId}
        background={joinRequirement.background}
        showHeart={true} onRepeatPress={onRepeatPress}
        showSteal={false} userData={props.userData}
        lessonData={props.lessonData} fromNotification={props.fromNotification}
      />}
      {/* {gameState === GameState.Won && (
        <div style={styles.prizeContainer}>
          <img src={props.engagementData.source.avatarBig} className={classes.logo} />
          <span className={classes.correctAnswer}>CORRECT ANSWER:</span>
          <span className={classes.answer}>{word.toUpperCase()}</span>
          <span className={classes.scratchWinMuch}>You won</span>
          {hofWinData.amount > 0 && (
            <div>
              <div className={classes.coinImageText}>
                <div className={classes.coinImageTextRow}>
                  <img
                    src={Coin}
                    className={classes.coinYellowImage}
                  />
                  <h2 className={classes.coinText}>
                    {hofWinData.amount}
                  </h2>
                </div>
                
              </div>
              <div className={classes.availableProtectCoin}>
              
              </div>
              <div className={classes.scoreCounterRow}>
                
              </div>
            </div>
          )}
          {hofWinData.product && (
            <div>
              <img
                src={hofWinData.product.imageBig}
                alt="bengga coin img"
                className={classes.scratchWinProductImage}
              />
            </div>
          )}
          <div style={{marginTop: '-20px', justifyContent: 'center', width: '100%', display: 'flex', backgroundColor: 'transparent'}}>
            {[...Array(guessLeft)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={HeartOn} key={i} />)}{[...Array(6-guessLeft)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={HeartOff} key={i} />)}
          </div>

          <div style={styles.buttonContainer}>
            <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
              BACK
            </button>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
          </div>
        </div>
      )}
      {gameState === GameState.Lost && (
        <div style={styles.prizeContainer}>
          <img src={props.engagementData.source.avatarBig} className={classes.logo} />
          <span className={classes.correctAnswer}>CORRECT ANSWER:</span>
          <span className={classes.answer}>{word.toUpperCase()}</span>
          <span className={classes.scratchWinMuch}>Try again</span>
          <div>
            <img
              src={props.engagementData.postloaderImageBig}
              alt="bengga coin img"
              className={classes.lostImage}
            />
          </div>

          <div style={styles.buttonContainer}>
            <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
              BACK
            </button>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
          </div>
        </div>
      )} */}
      <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
        <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.home)} hasQuitBtn={true} onQuitPress={onQuitPress} title='' style={{position: 'absolute'}}/>
      </div>
      <div className={`bg_modal_background ${showMaxJoin && 'modal_bg_show_no_delay'}`} style={{backgroundColor: 'black'}}></div>
      <div className={`bg_modal ${showMaxJoin && 'modal_show'}`}
        onClick={() => navigate(routes.home)}
      >
        <img src={MaxJoinTopic} className={classes.tutorialImage} />
      </div>
    </div>
  );
}

export default App;
