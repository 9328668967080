import { makeStyles } from "@material-ui/core/styles";
import { isAndroid, isIOS, isMobile } from "react-device-detect";

export default makeStyles((theme) => ({
  mainfooter: {
    position: "absolute",
    // top:
    //   (isIOS && "calc(100vh - 230px)") ||
    //   (isAndroid && "calc(80vh - 70px)") ||
    //   (!isMobile && "calc(100vh - 179px)"),
    bottom: 30,
    width: "100%",
    height: 50,
    zIndex: 11,
    backgroundColor: 'black'
    // backgroundColor: 'red'
  },
  morefooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    color: "#949494",
  },
  leftfootericn: {
    width: 50,
    cursor: "pointer",
    height: 50,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 20,
    zIndex: 3,
    objectFit: "contain",
    left: 20,
    position: 'absolute'
  },
  rightfootericn: {
    width: 50,
    cursor: "pointer",
    height: 50,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 20,
    zIndex: 3,
    objectFit: "contain",
    right: 20,
    position: 'absolute'
  },
  centerfootericn: {
    width: 75,
    cursor: "pointer",
    height: 75,
    // backgroundColor: 'red',
    paddingLeft: 0,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 14,
    zIndex: 3,
    objectFit: "contain",
    // position: 'absolute',
    
  },
  centerfootericnlabel: {
    textAlign: 'center',
    color: 'white',
    fontSize: 14
  }
}));