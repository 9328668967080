import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useStyles from "./styles";
import useWindowDimensions from "../../../utils/windowDimention";
import Loader from "../../../components/Common/Loader";
import InnerPageHeader from "../../../components/InnerPageHeader";
import { ids, routes } from "../../../constants";
import ScoreCounter from '../../../components/ScoreCounter';
import Buy from '../../../assets/images/common-images/buy.png';
import NotEnough from '../../../assets/images/common-images/not_enough_prompt.png';
// import ScoreImage from '../../../assets/images/header-images/Bengga Coin.png';
import {getCoinImage} from '../../../utils/utils';

import { buyStoreItem } from "../../../services/ApiService";
import { formatNumberWithComma } from "../../../utils/utils";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const StoreDetails = ({ history }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const props = useLocation().state;
  const { height, width } = useWindowDimensions();
  const [loader, setLoader] = useState(false);
  const [showNotEnoughPoints, setShowNotEnoughPoints] = useState(false);

  useEffect(() => {
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: false });
    return () => {
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchmove", preventTouch, {
        passive: false,
      });
    };
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onBuyClick = () => {
    if (props.balance < props.storeItemData.bmValue) {
      setShowNotEnoughPoints(true)
    }
    else {
      const rowData = props.storeItemData;
      const param = {
        "storeId": props.storeId,
        "storeItemId": rowData.id,
        "isTicket": false,
        "storeItemCategoryId": ids.storeCategoryGC,
        "efftWalletAddress": null,
        "wefftWalletAddress": null,
        "forBrands": rowData.forBrands
      }
      buyStoreItem(param)
      .then(({data}) => {
        navigate(-1);
      })
      .catch(e => {
        console.warn('buy item ERROR', e.message);
      });
    }
  }

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div
          style={{
            minWidth: 400,
            justifyContent: "center",
            maxWidth: 400,
            marginTop: 50,
          }}
        >
          <div className={classes.itemContainer}>
            <img
              src={props.storeItemData.imageBig}
              className={classes.image}
            />
            <div className={classes.amountContainer}>
              <img
                src={getCoinImage()}
                style={{width: 48, height: 48}}
              />
              <p className={classes.amount}>{formatNumberWithComma(props.storeItemData.bmValue, 0)}</p>
            </div>
            {props.storeItemData.isBought == 0 && <img
              src={Buy}
              className={classes.buy}
              onClick={onBuyClick}
            />}
          </div>
          <div className={classes.detailsContainer}>
            <p className={classes.bearer}>This item entitles the bearer</p>
            <p className={classes.prodName}>One(1) {props.storeItemData.description}</p>
            <div style={{display: 'flex', flexDirection: 'row', maxWidth: 400, minWidth: 400, marginTop: 20}}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                <p className={classes.title}>FOR USE IN</p>
                <p className={classes.value}>Abiva App</p>
              </div>
              <div style={{height: 60, width: 1, backgroundColor: '#00599d'}}></div>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                <p className={classes.title}>AVAILABLE STOCK</p>
                <p className={classes.value}>66</p>
              </div>
            </div>
            <p className={classes.please}>Please note: <br/><br/>·  Each user is allowed to buy only one (1) quantity per item per day.<br/>·  Redemption code is valid for single use only.</p>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: "black",
            alignItems: "center",
            width: "100%",
          }}
        >
          <InnerPageHeader
            hasBackBtn
            goBackClick={() => navigate(-1)}
            title={``}
            style={{ position: "absolute" }}
          />
          <div className={classes.balanceContainer}>
            <ScoreCounter balance={props.balance}/>
          </div>
        </div>
        <div className={`bg_modal_background ${showNotEnoughPoints && 'modal_bg_show'}`}></div>
        <div className={`bg_modal ${showNotEnoughPoints && 'modal_show'}`}
          onClick={() => setShowNotEnoughPoints(false)}
        >
          <img src={NotEnough} className={classes.tutorialImage} />
        </div>
      </div>
    </div>
  );
};

export default StoreDetails;
