import { QuestionType } from './'

const question_easy: QuestionType[] = [  
  {
    questionId: 10301,
    setting: "Lion",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Pixel art"
        ]
      },
      {
        correct_answer: "Mane",
        incorrect_answers: [
          "Hair"
        ]
      },
      {
        correct_answer: "Pride",
        incorrect_answers: [
          "Pack"
        ]
      },
      {
        correct_answer: "Cubs",
        incorrect_answers: [
          "Babies"
        ]
      },
      {
        correct_answer: "Club",
        incorrect_answers: [
          "Gang"
        ]
      },
      {
        correct_answer: "LKMEX Farming",
        incorrect_answers: [
          "LKMAX Farming"
        ]
      },
      {
        correct_answer: "Elrond Lions Club",
        incorrect_answers: [
          "Whaley",
          "Super Rare Bears"
        ]
      },
    ]
  },
  {
    questionId: 20301,
    setting: "Whale",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Abstract art"
        ]
      },
      {
        correct_answer: "Tail",
        incorrect_answers: [
          "Fin"
        ]
      },
      {
        correct_answer: "Mammal", 
        incorrect_answers: [
          "Fish" 
        ]
      },
      {
        correct_answer: "RAPT Token", 
        incorrect_answers: [
          "ZORG Token"
        ]
      },
      {
        correct_answer: "Whaley Vale",
        incorrect_answers: [
          "Christian Whale"
        ]
      },
      {
        correct_answer: "4,000 supply",
        incorrect_answers: [
          "6,000 supply"
        ]
      },
      {
        correct_answer: "Whaley",
        incorrect_answers: [
          "Elrond Lions Club",
          "MaiarPunks"
        ]
      },
    ]
  },

{
    questionId: 210301,
    setting: "Effort",
    questions: [
      {
        correct_answer: "Web 3",
        incorrect_answers: [
          "Web 2"
        ]
      },
      {
        correct_answer: "Crypto",
        incorrect_answers: [
          "Stocks"
        ]
      },
      {
        correct_answer: "App",
        incorrect_answers: [
          "Protocol"
        ]
      },
      {
        correct_answer: "P2E",
        incorrect_answers: [
          "E2E"
        ]
      },
      {
        correct_answer: "Exert",
        incorrect_answers: [
          "Lax"
        ]
      },
      {
        correct_answer: "Economy",
        incorrect_answers: [
          "Community"
        ]
      },
      {
        correct_answer: "Effort Economy",
        incorrect_answers: [
          "Elrond Network",
          "Mr. Ghost"
        ]
      },
    ]
  },
  {
    questionId: 310301,
    setting: "Elrond",
    questions: [
      {
        correct_answer: "Network",
        incorrect_answers: [
          "System"
        ]
      },
      {
        correct_answer: "EGLD",
        incorrect_answers: [
          "BNB"
        ]
      },
      {
        correct_answer: "Internet Scale Blockchain",
        incorrect_answers: [
          "Wide Scale Blockchain"
        ]
      },
      {
        correct_answer: "Lord of the Rings",
        incorrect_answers: [
          "Narnia"
        ]
      },
      {
        correct_answer: "Beniamin Mincu",
        incorrect_answers: [
          "Whaley Vale"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "CoinGecko"
        ]
      },
      {
        correct_answer: "Elrond Network",
        incorrect_answers: [
          "Effort Economy",
          "Web 3"
        ]
      },
    ]
  },
{
    questionId: 30301,
    setting: "Bear",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Landscape art"
        ]
      },
      {
        correct_answer: "Rare", 
        incorrect_answers: [
          "Uncommon" 
        ]
      },
      {
        correct_answer: "Syringe",
        incorrect_answers: [
          "Pill" 
        ]
      },
      {
        correct_answer: "RARE Token", 
        incorrect_answers: [
          "SRB Token" 
        ]
      },
      {
        correct_answer: "Abomination", 
        incorrect_answers: [
          "Monstrosity" 
        ]
      },
      {
        correct_answer: "10,000 supply", 
        incorrect_answers: [
          "8,000 supply" 
        ]
      },
      {
        correct_answer: "Super Rare Bears",
        incorrect_answers: [
          "Elrond Lions Club",
          "Whaley"
        ]
      },
    ]
  },
{
    questionId: 410301,
    setting: "Crypto",
    questions: [
      {
        correct_answer: "Gas",
        incorrect_answers: [
          "Tax"
        ]
      },
      {
        correct_answer: "Web",
        incorrect_answers: [
          "Internet"
        ]
      },
      {
        correct_answer: "Non Fungible Token",
        incorrect_answers: [
          "Non Funky Token"
        ]
      },
      {
        correct_answer: "Version 3",
        incorrect_answers: [
          "Version 2"
        ]
      },
      {
        correct_answer: "Blockchain",
        incorrect_answers: [
          "Web Ecosystem"
        ]
      },
      {
        correct_answer: "Smart Contract",
        incorrect_answers: [
          "Smart Program"
        ]
      },
      {
        correct_answer: "Web 3",
        incorrect_answers: [
          "Effort Economy",
          "Elrond Network"
        ]
      },
    ]
  },
{
    questionId: 40301,
    setting: "Punks",
    questions: [
      {
        correct_answer: "Pixel art",
        incorrect_answers: [
          "3D art"
        ]
      },
      {
        correct_answer: "The Chaldur",
        incorrect_answers: [
          "Dr. Gaban"
        ]
      },
      {
        correct_answer: "TOKIEN Token", 
        incorrect_answers: [
          "ZORG Token" 
        ]
      },
      {
        correct_answer: "Wizard",
        incorrect_answers: [
          "Witch"
        ]
      },
      {
        correct_answer: "Gen One",
        incorrect_answers: [
          "Gen Z"
        ]
      },
      {
        correct_answer: "Maiar",
        incorrect_answers: [
          "Orc"
        ]
      },
      {
        correct_answer: "MaiarPunks",
        incorrect_answers: [
          "Elva Forest",
          "Mr. Ghost"
        ]
      },
    ]
  },
{
    questionId: 90301,
    setting: "Bros",
    questions: [
      {
        correct_answer: "Creepy",
        incorrect_answers: [
          "Peacful"
        ]
      },
      {
        correct_answer: "Yellow Eyes",
        incorrect_answers: [
          "Blue Eyes"
        ]
      },
      {
        correct_answer: "Igor",
        incorrect_answers: [
          "Ivan"
        ]
      },
      {
        correct_answer: "House",
        incorrect_answers: [
          "Castle"
        ]
      },
      {
        correct_answer: "Bat",
        incorrect_answers: [
          "Bird"
        ]
      },
      {
        correct_answer: "Italy",
        incorrect_answers: [
          "Transylvania"
        ]
      },
      {
        correct_answer: "Maka-Bros",
        incorrect_answers: [
          "Evoluzion",
          "Super Rare Bears"
        ]
      },
    ]
  },
  {
    questionId: 80301,
    setting: "Makati City",
    questions: [
    {
      correct_answer: "A global city",
      incorrect_answers: [
        "A city lite"
      ]
    },
    {
      correct_answer: "80k+ business establishments",
      incorrect_answers: [
          "100k+ business establishments"
        ]
    },
    {
      correct_answer: "Upscale shopping malls",
      incorrect_answers: [
        "Tiangge shopping malls"
      ]
    },
    {
      correct_answer: "Phil. Stock Exchange",
      incorrect_answers: [
        "New York Stock Exchange"
      ]
    },
    {
      correct_answer: "Makati Business Club",
      incorrect_answers: [
        "Polo Business Club"
      ]
    },
    {
      correct_answer: "Makati Dev't. Corp.",
      incorrect_answers: [
        "Makati Hot Spots"
      ]
    },
    {
      correct_answer: "Financial Capital of the Phils.",
      incorrect_answers: [
        "Tiger City",
        "Pearl of the Orient"
      ]
    },
  ]
  },
{
    questionId: 100301,
    setting: "Senior Citizens",
    questions: [
      {
        correct_answer: "P100k cash gift on 100th Bday",
        incorrect_answers: [
          "P100k check gift on 100th Bday"
        ]
      },
      {
        correct_answer: "Up to P10k annual cash gift",
        incorrect_answers: [
          "Up to P5k annual cash gift"
        ]
      },
      {
        correct_answer: "Unlimited free movies",
        incorrect_answers: [
          "Limited free movies"
        ]
      },
      {
        correct_answer: "Free Bday & Golden Wedding Anniversary cakes",
        incorrect_answers: [
          "Free Bday & Wedding Anniversary cakes"
        ]
      },
      {
        correct_answer: "Free medicines",
        incorrect_answers: [
          "Sample medicines"
        ]
      },
      {
        correct_answer: "Subsidized health care services",
        incorrect_answers: [
          "Discounted health care services"
        ]
      },
      {
        correct_answer: "Blu Card",
        incorrect_answers: [
          "White Card",
          "Green Card"
        ]
      },
    ]
  },
{
    questionId: 70301,
    setting: "Makati Government",
    questions: [
      {
        correct_answer: "Marcelino Magsaysay",
        incorrect_answers: [
          "Ramon Magsaysay"
        ]
      },
      {
        correct_answer: "NemesioYabut",
        incorrect_answers: [
          "Rafael Yabut"
        ]
      },
      {
        correct_answer: "Jejomar Binay",
        incorrect_answers: [
          "Arsenio Lacson"
        ]
      },
      {
        correct_answer: "Romulo ‘Kid’ Pena Jr.",
        incorrect_answers: [
          "Braulio Escudero"
        ]
      },
      {
        correct_answer: "Junjun Binay",
        incorrect_answers: [
          "Francis Zamora"
        ]
      },
      {
        correct_answer: "Abigail Binay",
        incorrect_answers: [
          "Honey Lacuna"
        ]
      },
      {
        correct_answer: "Makati Mayors",
        incorrect_answers: [
          "Makati Congressmen",
          "Makati Kagawads"
        ]
      },
    ]
  },
{
    questionId: 50301,
    setting: "EMBO",
    questions: [
     {
        correct_answer: "Enlisted",
        incorrect_answers: [
          "Enlightened"
        ]
      },
      {
        correct_answer: "Men",
        incorrect_answers: [
          "Women"
        ]
      },
      {
        correct_answer: "Barrio",
        incorrect_answers: [
          "Barangay"
        ]
      },
      {
        correct_answer: "Makati",
        incorrect_answers: [
          "Manila"
        ]
      },
      {
        correct_answer: "REMBO",
        incorrect_answers: [
          "RAMBO"
        ]
      },
      {
        correct_answer: "COMEMBO",
        incorrect_answers: [
          "CEMBOT"
        ]
      },
      {
        correct_answer: "Enlisted Men's Barrio",
        incorrect_answers: [
          "Enlightened Men's Barrio",
          "Enlisted Men's Barangay"
        ]
      },
    ]
  },
{
    questionId: 60301,
    setting: "Makati City",
    questions: [
      {
        correct_answer: "Greenbelt",
        incorrect_answers: [
          "Tutuban Mall"
        ]
      },
      {
        correct_answer: "Glorietta",
        incorrect_answers: [
          "Ever Gotesco"
        ]
      },
      {
        correct_answer: "SM Makati",
        incorrect_answers: [
          "SM Manila"
        ]
      },
      {
        correct_answer: "Landmark",
        incorrect_answers: [
          "Eastwood"
        ]
      },
      {
        correct_answer: "Makati Cinema Square",
        incorrect_answers: [
          "Ali Mall"
        ]
      },
      {
        correct_answer: "Power Plant",
        incorrect_answers: [
          "Cubao Expo"
        ]
      },
      {
        correct_answer: "Makati Shopping Malls",
        incorrect_answers: [
          "Makati Hospitals",
          "Makati Hotels"
        ]
      },
    ]
  },
{
    questionId: 10302,
    setting: "Metro Manila City",
    questions: [
      {
        correct_answer: "33 Barangays",
        incorrect_answers: [
          "16 Barangays"
        ]
      },
      {
        correct_answer: "Population of 629,616 (2020)",
        incorrect_answers: [
          "Population of 459,159 (2020)"
        ]
      },
      {
        correct_answer: "Caracol Festival",
        incorrect_answers: [
          "Sapatos Festival"
        ]
      },
      {
        correct_answer: "Business Capital of the Phils.",
        incorrect_answers: [
          "Shoe Capital of the Phils."
        ]
      },
      {
        correct_answer: "80k+ Business establishments",
        incorrect_answers: [
          "16k+ Business establishments"
        ]
      },
      {
        correct_answer: "Abigail Binay",
        incorrect_answers: [
          "Marcelino Teodoro"
        ]
      },
      {
        correct_answer: "Makati",
        incorrect_answers: [
          "Marikina",
          "Manila"
        ]
      },
    ]
  },
{
    questionId: 20302,
    setting: "Thoroughfares",
    questions: [
      {
        correct_answer: "Ayala Ave.",
        incorrect_answers: [
          "Ortigas Ave."
        ]
      },
      {
        correct_answer: "Buendia Ave.",
        incorrect_answers: [
          "Boni Ave."
        ]
      },
      {
        correct_answer: "Makati Ave.",
        incorrect_answers: [
          "C.M. Recto Ave."
        ]
      },
      {
        correct_answer: "Arnaiz Ave.",
        incorrect_answers: [
          "Taft Ave."
        ]
      },
      {
        correct_answer: "Bel-Air St.",
        incorrect_answers: [
          "Balete Drive"
        ]
      },
      {
        correct_answer: "Paseo de Roxas St.",
        incorrect_answers: [
          "Batasan Road"
        ]
      },
      {
        correct_answer: "Makati Street Names",
        incorrect_answers: [
          "Manila Street Names",
          "Quezon City Street Names"
        ]
      },
    ]
  },
{
    questionId: 210302,
    setting: "Clan",
    questions: [
      {
        correct_answer: "Jejomar",
        incorrect_answers: [
          "Joseph"
        ]
      },
      {
        correct_answer: "Elenita",
        incorrect_answers: [
          "Loi"
        ]
      },
      {
        correct_answer: "Nancy",
        incorrect_answers: [
          "Jackie"
        ]
      },
      {
        correct_answer: "Abigail",
        incorrect_answers: [
          "Jerika"
        ]
      },
      {
        correct_answer: "Jejomar Jr.",
        incorrect_answers: [
          "Jinggoy"
        ]
      },
      {
        correct_answer: "Makati",
        incorrect_answers: [
          "San Juan"
        ]
      },
      {
        correct_answer: "Binay",
        incorrect_answers: [
          "Estrada",
          "Revilla"
        ]
      },
    ]
  },
{
    questionId: 310302,
    setting: "Elrond Network",
    questions: [
      {
        correct_answer: "Network",
        incorrect_answers: [
          "System"
        ]
      },
      {
        correct_answer: "EGLD",
        incorrect_answers: [
          "BNB"
        ]
      },
      {
        correct_answer: "Dr. Elrond",
        incorrect_answers: [
          "Alexander43"
        ]
      },
      {
        correct_answer: "Lord of the Rings",
        incorrect_answers: [
          "Narnia"
        ]
      },
      {
        correct_answer: "Beniamin Mincu",
        incorrect_answers: [
          "Whaley Vale"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "CoinGecko"
        ]
      },
      {
        correct_answer: "Internet Scale Blockchain",
        incorrect_answers: [
          "Wide Scale Blockchain",
          "Massive Scale Blockchain"
        ]
      },
    ]
  },
{
    questionId: 30302,
    setting: "Super Rare Bears",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Landscape art"
        ]
      },
      {
        correct_answer: "Rare",
        incorrect_answers: [
          "Uncommon"
        ]
      },
      {
        correct_answer: "Syringe",
        incorrect_answers: [
          "Pill"
        ]
      },
      {
        correct_answer: "RARE Token",
        incorrect_answers: [
          "SRB Token"
        ]
      },
      {
        correct_answer: "Abominator",
        incorrect_answers: [
          "Transformer"
        ]
      },
      {
        correct_answer: "10,000 supply",
        incorrect_answers: [
          "8,000 supply"
        ]
      },
      {
        correct_answer: "Abomination",
        incorrect_answers: [
          "Monstrosity",
          "Obscenity"
        ]
      },
    ]
  },
{
    questionId: 410302,
    setting: "Web 3",
    questions: [
      {
        correct_answer: "Gas",
        incorrect_answers: [
          "Tax"
        ]
      },
      {
        correct_answer: "Web",
        incorrect_answers: [
          "Internet"
        ]
      },
      {
        correct_answer: "Non Fungible Token",
        incorrect_answers: [
          "Non Funky Token"
        ]
      },
      {
        correct_answer: "Version 3",
        incorrect_answers: [
          "Version 2"
        ]
      },
      {
        correct_answer: "Blockchain",
        incorrect_answers: [
          "Web Ecosystem"
        ]
      },
      {
        correct_answer: "Smart Contract",
        incorrect_answers: [
          "Smart Program"
        ]
      },
      {
        correct_answer: "Crypto",
        incorrect_answers: [
          "Stocks",
          "Forex"
        ]
      },
    ]
  },
{
    questionId: 40302,
    setting: "MaiarPunks",
    questions: [
      {
        correct_answer: "Pixel art",
        incorrect_answers: [
          "3D art"
        ]
      },
      {
        correct_answer: "The Chaldur",
        incorrect_answers: [
          "Dr. Gaban"
        ]
      },
      {
        correct_answer: "TOKIEN Token",
        incorrect_answers: [
          "ZORG Token"
        ]
      },
      {
        correct_answer: "Wizard",
        incorrect_answers: [
          "Witch"
        ]
      },
      {
        correct_answer: "Gen One",
        incorrect_answers: [
          "Gen Z"
        ]
      },
      {
        correct_answer: "Maiar",
        incorrect_answers: [
          "Orc"
        ]
      },
      {
        correct_answer: "Andurin",
        incorrect_answers: [
          "Rohan",
          "Mordor"
        ]
      },
    ]
  },
{
    questionId: 90302,
    setting: "Maka-Bros",
    questions: [
      {
        correct_answer: "Creepy",
        incorrect_answers: [
          "Peaceful"
        ]
      },
      {
        correct_answer: "House",
        incorrect_answers: [
          "Castle"
        ]
      },
      {
        correct_answer: "Hall",
        incorrect_answers: [
          "Corridor"
        ]
      },
      {
        correct_answer: "Human",
        incorrect_answers: [
          "Animal"
        ]
      },
      {
        correct_answer: "Yellow Eyes",
        incorrect_answers: [
          "Blue Eyes"
        ]
      },
      {
        correct_answer: "Female",
        incorrect_answers: [
          "Male"
        ]
      },
      {
        correct_answer: "Wanda",
        incorrect_answers: [
          "Wonka",
          "Wilma"
        ]
      },
    ]
  },
{
    questionId: 80302,
    setting: "OrcPunks",
    questions: [
      {
        correct_answer: "Green skin",
        incorrect_answers: [
          "Blue skin"
        ]
      },
      {
        correct_answer: "Big teeth",
        incorrect_answers: [
          "Small teeth"
        ]
      },
      {
        correct_answer: "Weapons",
        incorrect_answers: [
          "Flowers"
        ]
      },
      {
        correct_answer: "ZORG Token",
        incorrect_answers: [
          "RARE Token"
        ]
      },
      {
        correct_answer: "Field background",
        incorrect_answers: [
          "Plain background"
        ]
      },
      {
        correct_answer: "Punks",
        incorrect_answers: [
          "Delinquent"
        ]
      },
      {
        correct_answer: "Pixel art",
        incorrect_answers: [
          "Abstract art",
          "Landscape art"
        ]
      },
    ]
  },
{
    questionId: 100302,
    setting: "Elrond Mutant Eheadz",
    questions: [
      {
        correct_answer: "Laboratory",
        incorrect_answers: [
          "Junkyard"
        ]
      },
      {
        correct_answer: "Element",
        incorrect_answers: [
          "Compound"
        ]
      },
      {
        correct_answer: "Chemicals",
        incorrect_answers: [
          "Energy Drink"
        ]
      },
      {
        correct_answer: "Mutation",
        incorrect_answers: [
          "Evolution"
        ]
      },
      {
        correct_answer: "EME",
        incorrect_answers: [
          "AMA"
        ]
      },
      {
        correct_answer: "Doctor",
        incorrect_answers: [
          "Scientist"
        ]
      },
      {
        correct_answer: "Dr. Gaban",
        incorrect_answers: [
          "Dr. Elrond",
          "Dr. No"
        ]
      },
    ]
  },
{
    questionId: 70302,
    setting: "Mr. Ghost",
    questions: [
      {
        correct_answer: "Governance token",
        incorrect_answers: [
          "Native token"
        ]
      },
      {
        correct_answer: "Elven app",
        incorrect_answers: [
          "Orc app"
        ]
      },
      {
        correct_answer: "Paranormal",
        incorrect_answers: [
          "Normal"
        ]
      },
      {
        correct_answer: "Dead",
        incorrect_answers: [
          "Alive"
        ]
      },
      {
        correct_answer: "White",
        incorrect_answers: [
          "Black"
        ]
      },
      {
        correct_answer: "Laboratory",
        incorrect_answers: [
          "Junkyard"
        ]
      },
      {
        correct_answer: "Gokai Labs",
        incorrect_answers: [
          "Mutant Labs",
          "Genes Laboratory"
        ]
      },
    ]
  },
{
    questionId: 50302,
    setting: "Elva Forest",
    questions: [
      {
        correct_answer: "Forest",
        incorrect_answers: [
          "Mountains"
        ]
      },
      {
        correct_answer: "Fantasy",
        incorrect_answers: [
          "Science Fiction"
        ]
      },
      {
        correct_answer: "Odyssey Collection",
        incorrect_answers: [
          "Genesis Collection"
        ]
      },
      {
        correct_answer: "Online to Offline business",
        incorrect_answers: [
          "Brand to Brand business"
        ]
      },
      {
        correct_answer: "Aesthetic Creations",
        incorrect_answers: [
          "Musical Creations"
        ]
      },
      {
        correct_answer: "Elva",
        incorrect_answers: [
          "Elvis"
        ]
      },
      {
        correct_answer: "Elf",
        incorrect_answers: [
          "Orc",
          "Dwarf"
        ]
      },
    ]
  },
{
    questionId: 60302,
    setting: "Evoluzion",
    questions: [
      {
        correct_answer: "Evolve",
        incorrect_answers: [
          "Mutate"
        ]
      },
      {
        correct_answer: "Genes Laboratory",
        incorrect_answers: [
          "Gokai Labs"
        ]
      },
      {
        correct_answer: "Primates",
        incorrect_answers: [
          "Felines"
        ]
      },
      {
        correct_answer: "Humans",
        incorrect_answers: [
          "Baboons"
        ]
      },
      {
        correct_answer: "Cyborg",
        incorrect_answers: [
          "Robot"
        ]
      },
      {
        correct_answer: "Evolzionists",
        incorrect_answers: [
          "Extortionists"
        ]
      },
      {
        correct_answer: "3D art",
        incorrect_answers: [
          "Pixel art",
          "Abstract art"
        ]
      },
    ]
  },
{
    questionId: 210303,
    setting: "Effort Economy",
    questions: [
      {
        correct_answer: "Elrond",
        incorrect_answers: [
          "Ethereum"
        ]
      },
      {
        correct_answer: "Effort-to-Earn",
        incorrect_answers: [
          "Play-to-Earn"
        ]
      },
      {
        correct_answer: "Earning",
        incorrect_answers: [
          "Saving"
        ]
      },
      {
        correct_answer: "Many apps",
        incorrect_answers: [
          "One app"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "Uniswap"
        ]
      },
      {
        correct_answer: "Exclusive",
        incorrect_answers: [
          "Inclusive"
        ]
      },
      {
        correct_answer: "$EFFORT",
        incorrect_answers: [
          "$EFT",
          "$EFRT"
        ]
      },
    ]
  },
{
    questionId: 210304,
    setting: "Effort Economy",
    questions: [
      {
        correct_answer: "Entertainment",
        incorrect_answers: [
          "Remittance"
        ]
      },
      {
        correct_answer: "Many apps",
        incorrect_answers: [
          "One app"
        ]
      },
      {
        correct_answer: "Effort-to-Earn",
        incorrect_answers: [
          "Play-to-Earn"
        ]
      },
      {
        correct_answer: "100,000,000",
        incorrect_answers: [
          "1,000,000,000"
        ]
      },
      {
        correct_answer: "Internet Scale Adoption",
        incorrect_answers: [
          "Limited Scale Adoption"
        ]
      },
      {
        correct_answer: "Effort",
        incorrect_answers: [
          "Exert"
        ]
      },
      {
        correct_answer: "$EFFORT",
        incorrect_answers: [
          "$EFT",
          "$EFRT"
        ]
      },
    ]
  },
{
    questionId: 310303,
    setting: "Elrond Network",
    questions: [
      {
        correct_answer: "Romania",
        incorrect_answers: [
          "America"
        ]
      },
      {
        correct_answer: "EGLD",
        incorrect_answers: [
          "ELRD"
        ]
      },
      {
        correct_answer: "Influencer",
        incorrect_answers: [
          "Validator"
        ]
      },
      {
        correct_answer: "Maiar Wallet",
        incorrect_answers: [
          "Metamask"
        ]
      },
      {
        correct_answer: "Proof of Stake",
        incorrect_answers: [
          "Proof of work"
        ]
      },
      {
        correct_answer: "Layer 1",
        incorrect_answers: [
          "Layer 2"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "CoinGecko",
          "Gate.io"
        ]
      },
    ]
  },
{
    questionId: 310304,
    setting: "Elrond Network",
    questions: [
      {
        correct_answer: "Lord of the Rings",
        incorrect_answers: [
          "Narnia"
        ]
      },
      {
        correct_answer: "Internet scale",
        incorrect_answers: [
          "Limited scale"
        ]
      },
      {
        correct_answer: "Network",
        incorrect_answers: [
          "Fund"
        ]
      },
      {
        correct_answer: "Sharding",
        incorrect_answers: [
          "Mapping"
        ]
      },
      {
        correct_answer: "15,000 per second",
        incorrect_answers: [
          "1500 per second"
        ]
      },
      {
        correct_answer: "31,000,000",
        incorrect_answers: [
          "21,000,000"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "CoinGecko",
          "Gate.io"
        ]
      },
    ]
  },
{
    questionId: 410303,
    setting: "Web 3",
    questions: [
      {
        correct_answer: "Ownership",
        incorrect_answers: [
          "Interactive"
        ]
      },
      {
        correct_answer: "Decentralized",
        incorrect_answers: [
          "Centralized"
        ]
      },
      {
        correct_answer: "Fungible/NFT",
        incorrect_answers: [
          "Fiat currency"
        ]
      },
      {
        correct_answer: "Immutable",
        incorrect_answers: [
          "Revocable"
        ]
      },
      {
        correct_answer: "Global",
        incorrect_answers: [
          "Regional"
        ]
      },
      {
        correct_answer: "Gas",
        incorrect_answers: [
          "Tax"
        ]
      },
      {
        correct_answer: "Crypto",
        incorrect_answers: [
          "Stocks",
          "Forex"
        ]
      },
    ]
  },
{
    questionId: 410304,
    setting: "Web 3",
    questions: [
      {
        correct_answer: "Digital tokens",
        incorrect_answers: [
          "Digital vouchers"
        ]
      },
      {
        correct_answer: "Transaction history",
        incorrect_answers: [
          "Viewing history"
        ]
      },
      {
        correct_answer: "Smart contract",
        incorrect_answers: [
          "Submitted field"
        ]
      },
      {
        correct_answer: "Network of validators",
        incorrect_answers: [
          "Network of voters"
        ]
      },
      {
        correct_answer: "Transaction hash",
        incorrect_answers: [
          "Digital receipt"
        ]
      },
      {
        correct_answer: "Covered by network costs",
        incorrect_answers: [
          "Covered by server costs"
        ]
      },
      {
        correct_answer: "Crypto",
        incorrect_answers: [
          "Stocks",
          "Forex"
        ]
      },
]
},
{
    questionId: 700001,
    setting: "Establishments",
    questions: [
      {
        correct_answer: "Ali Mall",
        incorrect_answers: [
          "Tutuban Mall"
        ]
      },
      {
        correct_answer: "U.P. Town Center",
        incorrect_answers: [
          "Ever Gotesco"
        ]
      },
      {
        correct_answer: "SM Fairview",
        incorrect_answers: [
          "SM Manila"
        ]
      },
      {
        correct_answer: "Eastwood",
        incorrect_answers: [
          "Landmark"
        ]
      },
      {
        correct_answer: "Gateway",
        incorrect_answers: [
          "Makati Cinema Square"
        ]
      },
      {
        correct_answer: "Cubao Expo",
        incorrect_answers: [
          "Power Plant"
        ]
      },
      {
        correct_answer: "Quezon City Malls",
        incorrect_answers: [
          "Quezon City Hospitals",
          "Quezon City Hotels"
        ]
      },
    ]
  },
{
    questionId: 700002,
    setting: "City in Metro Manila",
    questions: [
      {
        correct_answer: "142 Barangays",
        incorrect_answers: [
          "33 Barangays"
        ]
      },
      {
        correct_answer: "Population of 2.9 million (2022)",
        incorrect_answers: [
          "Population of 629,616 (2022)"
        ]
      },
      {
        correct_answer: "La Loma Lechon Festival",
        incorrect_answers: [
          "Caracol Festival"
        ]
      },
      {
        correct_answer: "The City of Stars",
        incorrect_answers: [
          "Tiger City"
        ]
      },
      {
        correct_answer: "University of the Philippines",
        incorrect_answers: [
          "Philippine Stock Exchange"
        ]
      },
      {
        correct_answer: "Joy Belmonte",
        incorrect_answers: [
          "Abby Binay"
        ]
      },
      {
        correct_answer: "Quezon City",
        incorrect_answers: [
          "Makati",
          "Manila"
        ]
      },
    ]
  },
 {
    questionId: 700003,
    setting: "QC Government Project",
    questions: [
      {
        correct_answer: "Brgy Balingasa",
        incorrect_answers: [
          "Brgy Bahay Toro"
        ]
      },
      {
        correct_answer: "Brgy Baesa",
        incorrect_answers: [
          "Brgy Katipunan"
        ]
      },
      {
        correct_answer: "Brgy Payatas",
        incorrect_answers: [
          "Brgy Kamuning"
        ]
      },
      {
        correct_answer: "First from Danger Areas",
        incorrect_answers: [
          "First from Safe Areas"
        ]
      },
      {
        correct_answer: "To Build More",
        incorrect_answers: [
          "To Build Less"
        ]
      },
      {
        correct_answer: "Sharer/Renter",
        incorrect_answers: [
          "Owner"
        ]
      },
      {
        correct_answer: "QC Pabahay Projects",
        incorrect_answers: [
          "QC Livelihood Projects",
          "QC Educational Projects"
        ]
      },
    ]
  },
 {
    questionId: 700004,
    setting: "QC Government Project",
    questions: [
      {
        correct_answer: "Entrepreneurship",
        incorrect_answers: [
          "Employment"
        ]
      },
      {
        correct_answer: "Tindahan ni Ate Joy",
        incorrect_answers: [
          "Tindahan ni Aling Puring"
        ]
      },
      {
        correct_answer: "Lipad-Pinay Program",
        incorrect_answers: [
          "Lipad-Darna"
        ]
      },
      {
        correct_answer: "Artisan Academy",
        incorrect_answers: [
          "Police Academy"
        ]
      },
      {
        correct_answer: "Lazada E-Commerce",
        incorrect_answers: [
          "Tiangge Selling"
        ]
      },
      {
        correct_answer: "Joy Belmonte",
        incorrect_answers: [
          "Honey Lacuna"
        ]
      },
      {
        correct_answer: "QC Livelihood Projects",
        incorrect_answers: [
          "QC Pabahay Projects",
          "QC Educational Projects"
        ]
      },
    ]
  },
 {
    questionId: 700005,
    setting: "QC Government Project",
    questions: [
      {
        correct_answer: "Completed 99 infrastructure projects",
        incorrect_answers: [
          "Completed 33 infrastructure projects"
        ]
      },
      {
        correct_answer: "Has 26 0n-going projects",
        incorrect_answers: [
          "Has 10 0n-going projects"
        ]
      },
      {
        correct_answer: "Has 30 proposed projects now being evaluated",
        incorrect_answers: [
          "Has 15 proposed projects now being evaluated"
        ]
      },
      {
        correct_answer: "Provided 250,054 tablets with WIFI",
        incorrect_answers: [
          "Provided 120,105 tablets with WIFI"
        ]
      },
      {
        correct_answer: "Provided laptops with WIFI",
        incorrect_answers: [
          "Provided blackboards with chalks"
        ]
      },
      {
        correct_answer: "Accomplishments from 2019-2022",
        incorrect_answers: [
          "Accomplishments from 2012-2022"
        ]
      },
      {
        correct_answer: "QC Educational Projects",
        incorrect_answers: [
          "QC Livelihood Projects",
          "QC Pabahay Projects"
        ]
      },
    ]
  },
 {
    questionId: 700006,
    setting: "QC Benefits",
    questions: [
      {
        correct_answer: "Free Parking",
        incorrect_answers: [
          "Free Birthday Cake"
        ]
      },
      {
        correct_answer: "Transfer tax exemption",
        incorrect_answers: [
          "Coding exemption"
        ]
      },
      {
        correct_answer: "Free movie admissions",
        incorrect_answers: [
          "Discounted movie prices"
        ]
      },
      {
        correct_answer: "Free use of pay restrooms",
        incorrect_answers: [
          "Discounted use of pay restrooms"
        ]
      },
      {
        correct_answer: "Centenarians receive P1000 monthly allowance",
        incorrect_answers: [
          "Centenarians receive P500 monthly allowance"
        ]
      },
      {
        correct_answer: "Centenarians receive P1000 on birthdays and Christmas",
        incorrect_answers: [
          "Centenarians receive P500 on birthdays and Christmas"
        ]
      },
      {
        correct_answer: "QC Benefits for Senior Citizens",
        incorrect_answers: [
          "QC Benefits for Overseas Workers",
          "QC Benefits for Single Mothers"
        ]
      },
    ]
  },
 {
    questionId: 700007,
    setting: "Thoroughfares",
    questions: [
      {
        correct_answer: "Gilmore Ave.",
        incorrect_answers: [
          "Ayala Ave."
        ]
      },
      {
        correct_answer: "FPJ Ave.",
        incorrect_answers: [
          "Boni Ave."
        ]
      },
      {
        correct_answer: "Quezon Ave.",
        incorrect_answers: [
          "C.M. Recto Ave."
        ]
      },
      {
        correct_answer: "Tandang Sora Ave.",
        incorrect_answers: [
          "Taft Ave."
        ]
      },
      {
        correct_answer: "Balete Drive",
        incorrect_answers: [
          "Bel-Air St."
        ]
      },
      {
        correct_answer: "Batasan Road",
        incorrect_answers: [
          "Paseo de Roxas St."
        ]
      },
      {
        correct_answer: "Quezon City Street Names",
        incorrect_answers: [
          "Makati Street Names",
          "Manila Street Names"
        ]
      },
    ]
  },
 {
    questionId: 700008,
    setting: "Quezon City Government",
    questions: [
      {
        correct_answer: "Manuel Quezon",
        incorrect_answers: [
          "Ramon Magsaysay"
        ]
      },
      {
        correct_answer: "Adelina Rodriguez",
        incorrect_answers: [
          "Elenita Binay"
        ]
      },
      {
        correct_answer: "Ismael Mathay Jr.",
        incorrect_answers: [
          "Arsenio lacson"
        ]
      },
      {
        correct_answer: "Feliciano Belmonte",
        incorrect_answers: [
          "Braulio Escudero"
        ]
      },
      {
        correct_answer: "Herbert Bautista",
        incorrect_answers: [
          "Francis Zamora"
        ]
      },
      {
        correct_answer: "Joy Belmonte",
        incorrect_answers: [
          "Honey Lacuna"
        ]
      },
      {
        correct_answer: "Quezon City Mayors",
        incorrect_answers: [
          "Quezon City Congressmen",
          "Quezon City Kagawads"
        ]
      },
    ]
  }
]

;

export default question_easy;