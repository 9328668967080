import React from 'react';
import useStyles from './styles';
import ProfileIcon from '../ProfileIcon';
import CloseIcon from '../../assets/images/common-images/close.svg';
import { sidebarMenu } from '../../utils/sidebarMenu';
import SoundOn from '../../assets/images/sidebar-images/sound_on.png';
import SoundOff from '../../assets/images/sidebar-images/sound_off.png';

const Sidebar = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.sidebarContainer}>
        <div className={classes.profileContainer}>
          <ProfileIcon noCount avatarClicked={props.avatarClicked} avatar={props.avatar}/>
          <img
            className={classes.closeImage}
            src={CloseIcon}
            alt='Close'
            onClick={() => props.setSidebarOpen(false)}
          />
        </div>
        <div className={classes.menuContainer}>
          {sidebarMenu.map((menu, index) => (
            <div key={`item${index}`} className={classes.menuOuter}>
              <div
                className={classes.singleMenu}
                onClick={() => {
                  if (menu.id !== 5) {
                    props.setSidebarOpen(false)
                  }
                  props.sidebarIndexClicked(menu.id)
                }}
              >
                <img
                  className={classes.menuImage}
                  src={menu.id === 5 ? props.isSoundMuted ? SoundOff : SoundOn : menu.icon}
                  alt={menu.label}
                />
                {menu.id === 0 && props.notificationCount > 0 && <div className={classes.profileCounter}>{props.notificationCount}</div>}
                <label className={classes.menuLabel}>{menu.id === 5 ? props.isSoundMuted ? 'Game sound: Off' : 'Game sound: On' : menu.label}</label>
              </div>
              {(index == 0 || index == 2) && (
                <div className={classes.divider} />
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={classes.mask}
        onClick={() => props.setSidebarOpen(false)}
      />
    </>
  );
};

export default Sidebar;
