import Cashout from '../assets/images/footer-images/cashout.png';
import CashoutWhite from '../assets/images/footer-images/cashout_white.png';
import Medicines from '../assets/images/footer-images/medicines.png';
import MedicinesWhite from '../assets/images/footer-images/medicines_white.png';
import Groceries from '../assets/images/footer-images/groceries.png';
import GroceriesWhite from '../assets/images/footer-images/groceries_white.png';

export const storeFooterMenus = [
  {
    label: 'Cashout',
    icon: Cashout,
    iconWhite: CashoutWhite,
  },
  {
    label: 'Medicines',
    icon: Medicines,
    iconWhite: MedicinesWhite,
  },
  {
    label: 'Groceries',
    icon: Groceries,
    iconWhite: GroceriesWhite,
  },
];
