import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  scoreCounterContainer: {
    maxWidth: 170,
    minWidth: 150,
    background: "#FAFAFA",
    border: "1px solid #28BBB1",
    display: "flex",
    alignItems: "center",
    borderRadius: 30,
  },
  scoreContainer: {
    width: "100%",
    maxWidth: "calc(100% - 54px)",
    // height: "100%",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    paddingLeft: 5,
    textAlign: "center",
    // paddingRight: 5,
  },
  scoreImage: {
    width: 32,
    height: 32,
    marginLeft: 1,
  },
  scoreLabel: {
    lineHeight: 1.3,
    fontWeight: 850,
    fontFamily: "canaroMedium",
    fontSize: 16,
    margin: 0,
    wordBreak: "break-word",
    // color: 'white'
  },
}));
