import React, { useEffect, useState } from 'react';
import ProfileIcon from '../ProfileIcon';
import ScoreCounter from '../ScoreCounter';
// import Store from '../../assets/images/header-images/store.png';
import useStyles from './styles';
import { getStoreImage } from '../../utils/utils';
import Boosts from '../../assets/images/sidebar-images/boosts.png';

import {
  getUserBalance
} from "../../services/ApiService";
import Ticket from '../Ticket';

const Header = (props) => {
  const classes = useStyles();
  const [balance, setBalance] = useState(0);
  const [tickets, setTickets] = useState(0);

  useEffect(() => {
    fetchGetBalance();
  }, [])

  const fetchGetBalance = async () => {
    try {
      const response = await getUserBalance();

      if (response) {
        // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), process.env.REACT_APP_ENCRYPT_KEY).toString();
        localStorage.setItem(
          "balance",
          JSON.stringify(response.data.data)
        );
        setBalance(response.data.data.coinAmount);
        setTickets(response.data.data.ticketQuantity);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.mainHeaderContainer}>
      <div className={classes.profileContainer}>
        <ProfileIcon setSidebarOpen={props.setSidebarOpen} avatar={props.avatar} notificationCount={props.notificationCount}/>
      </div>
      <div className={classes.balanceContainer}>
        <ScoreCounter balance={balance}/>
      </div>
      {/* <div className={classes.ticketProfileContainer}>
        <Ticket balance={tickets} />
      </div> */}
      {/* <div className={classes.storeContainer}>
        <img
          className={classes.storeImage}
          src={getStoreImage()}
          alt='Score'
          onClick={() => props.storePressed()}
        />
      </div> */}
      <div className={classes.storeContainer}>
        <img
          className={classes.storeImage}
          src={Boosts}
          alt='Score'
          onClick={() => props.boostPressed()}
        />
      </div>
    </div>
  );
};

export default Header;
