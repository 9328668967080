import React, { useState } from 'react';
import useWindowDimensions from '../../utils/windowDimention';
import useStyles from './styles';

const ComponentData = (props) => {
  const classes = useStyles();
  const { height } = useWindowDimensions();

  const styles = {
    mainDiv: {
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      top: height - 142,
    },
  };

  return (
    <div style={styles.mainDiv}>
      <label className={classes.dataLabel}>{process.env.REACT_APP_PREFIX}: {props.name}</label>
    </div>
  );
};

export default ComponentData;
