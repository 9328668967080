import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  storeFooterMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 80
  },
  storeMenuIcon: {
    width: 33,
    // backgroundColor: 'red',
  },
  storeMenuLabel: {
    color: 'white',
    fontSize: 10,
    cursor: 'pointer',
    fontFamily: 'canaroBook',
    marginTop: 5,
    lineHeight: 1
  },
}));
