import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  homeContainer: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
    flexDirection: 'column'
  },
  homeContent: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
  homeContentLandscape: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    transform: 'rotate(270deg)'
  },
  sliderIndicatorContainer: {
    position: 'absolute',
    top: '50%',
    left: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sliderIndicator: {
    width: 7,
    height: 7,
    border: '1px solid white',
    borderRadius: '50%',
    marginBottom: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
  },
  avatar: {
    width: 300,
    height: 300,
    objectFit: 'cover',
    border: '4px solid white',
    borderRadius: '50%',
    borderColor: 'white',
  },
  avatarContainer: {
    width: 300,
    height: 300,
    marginLeft: 90,
    marginTop: 0,
  },
  address: {
    fontSize: 15,
    color: '#db3eb1',
    maxWidth: 300,
    marginTop: 20,
    wordWrap: 'break-word',
    marginLeft: 5
  },
  address1: {
    fontSize: 15,
    color: 'white',
    maxWidth: 300,
    marginTop: 20,
    wordWrap: 'break-word'
  },
  addresscontainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row'
  },
  name: {
    fontSize: 18,
    color: '#db3eb1',
    maxWidth: 300,
    marginTop: 40,
    wordWrap: 'break-word',
    textAlign: 'center'
  }
}));
