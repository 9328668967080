import { DictionaryType } from './'

const dictionary_en: DictionaryType[] = [
  {
    questionId: 10101,
    category: 'What are Senior Citizens exempted from in Makati?',
    words: [
      'Coding'
    ]
  },
  {
    questionId: 10102,
    category: 'What entitles residents of Makati to additional Senior Citizen benefits in 2022?',
    words: [
      'Blu Card'
    ]
  },
  {
    questionId: 10103,
    category: 'Makati is divided into how many Barangays?',
    words: [
      'Thirty Three'
    ]
  },
  {
    questionId: 10104,
    category: 'The current Mayor of Makati is',
    words: [
      'Abigail Binay'
    ]
  },
  {
    questionId: 10105,
    category: 'Who was the 1st Binay Mayor of Makati that was elected in 1995?',
    words: [
      'Jejomar Binay'
    ]
  },
{
    questionId: 10106,
    category: 'How many terms did Jejomar Binay serve?',
    words: [
      'Six'
    ]
  },
{
    questionId: 20101,
    category: "What is Abigail's relation to Jejomar?",
    words: [
      'Daughter'
    ]
  },
  {
    questionId: 20102,
    category: 'Who appointed Jejomar Binay in 1986?',
    words: [
      'Corazon Aquino'
    ]
  },
  {
    questionId: 20103,
    category: 'What is the original name of Makati City?',
    words: [
      'San Pedro de Macati'
    ]
  },
  {
    questionId: 20104,
    category: 'What is the meaning of the tagalog word "makati"?',
    words: [
      'Receding Tide'
    ]
  },
  {
    questionId: 20105,
    category: 'Who discovered Makati?',
    words: [
      'Don Manuel de Legaspi'
    ]
  }, 
{
    questionId: 20106,
    category: 'Population of Makati as of 2020 Census is estimated to be',
    words: [
      'Six hundred thousand'
    ]
  }, 
{
    questionId: 30101,
    category: 'What is the famous festival in Makati which started in 1989?',
    words: [
      'Caracol Festival'
    ]
  },
  {
    questionId: 30102,
    category: 'Who was the 1st mayor of Makati that was elected in 1901?',
    words: [
      'Marcelino Magsaysay'
    ]
  },
  {
    questionId: 30103,
    category: 'Feb. 4, 1995 was the date that Makati became a',
    words: [
      'City'
    ]
  },
  {
    questionId: 30104,
    category: 'Makati approximately has how many business establishments?',
    words: [
      'Eighty thousand'
    ]
  },
  {
    questionId: 30105,
    category: 'The Makati Development Corporation was founded in 19__',
    words: [
      'Seventy four'
    ]
  },
{
    questionId: 30106,
    category: 'The present Vice Mayor of Makati is',
    words: [
      'Monique Lagdameo'
    ]
  },
{
    questionId: 40101,
    category: 'Who is the current Congressman of Makati City?',
    words: [
      'Romulo Pena Jr'
    ]
  },
  {
    questionId: 40102,
    category: 'What is the nickname of Makati?',
    words: [
      'Business Capital of the Philippines'
    ]
  },
  {
    questionId: 40103,
    category: "In Makati, it means Enlisted Men's Barrio",
    words: [
      'EMBO'
    ]
  },
  {
    questionId: 40104,
    category: 'What does C in CEMBO stand for?',
    words: [
      'Central'
    ]
  },
  {
    questionId: 40105,
    category: 'What does P in PEMBO stand for?',
    words: [
      'Panthers'
    ]
  },
{
    questionId: 40106,
    category: 'What does R in REMBO stand for?',
    words: [
     'Riverside'
    ]
  },
{
    questionId: 50101,
    category: 'What does COM in COMEMBO stand for?',
    words: [
      'Combat'
    ]
  },
  {
    questionId: 50102,
    category: 'The kind of story that Elva Forest narrates is called',
    words: [
      'Fantasy Saga'
    ]
  },
  {
    questionId: 50103,
    category: 'What will Elva Forest distribute worldwide as they grow?',
    words: [
      'Elva Forest Merchandise'
    ]
  },
  {
    questionId: 50104,
    category: 'Elva Forest aims to bring this to the Elrond Ecosystem',
    words: [
      'Aesthetic Creations'
    ]
  },
  {
    questionId: 50105,
    category: 'What Elva Forest collection was recently released?',
    words: [
      'Odyssey Collection'
    ]
  },
{
    questionId: 50106,
    category: 'The residents of Elva Forest belong to the race of',
    words: [
      'Elves'
    ]
  },
{
    questionId: 60101,
    category: 'The 3 kinds of Evoluzionists in the Evoluzion collection are',
    words: [
      'Primates Humans Cyborgs'
    ]
  },
  {
    questionId: 60102,
    category: 'What do you call the 2 backgrounds for Legendary Evoluzion?',
    words: [
      'Candy Pumpkin'
    ]
  },
  {
    questionId: 60103,
    category: 'The individuals of the Evoluzion NFT collection are called',
    words: [
      'Evoluzionists'
    ]
  },
  {
    questionId: 60104,
    category: 'Where do you see Evoluzion NFT traits on their website?',
    words: [
      'Genes Laboratory'
    ]
  },
  {
    questionId: 60105,
    category: 'The 2 backgrounds for Rare Evoluzion NFTs are',
    words: [
      'Diamond Ketchup'
    ]
  },
{
    questionId: 60106,
    category: 'Genes Laboratory shows Evoluzion NFT _______',
    words: [
      'Traits'
    ]
  },
{
    questionId: 70101,
    category: 'What is the name of upcoming app from Gokai Labs?',
    words: [
      'Elven app'
    ]
  },
  {
    questionId: 70102,
    category: 'What do you call the Governance token of Gokai Labs?',
    words: [
      'Mr Ghost NFT'
    ]
  },
  {
    questionId: 70103,
    category: 'What feature lets one earn passively in the Elven app?',
    words: [
      'Staking'
    ]
  },
  {
    questionId: 70104,
    category: 'Creator of the Maiar Ghosts NFTs is',
    words: [
      'Gokai Labs'
    ]
  },
  {
    questionId: 70105,
    category: 'The app by Gokai Labs with an upgradeable smart contract is',
    words: [
      'Elven Tools Dapp'
    ]
  },
{
    questionId: 70106,
    category: 'Mr Ghost NFT token of Gokai Labs is also called',
    words: [
      'Governance token'
    ]
  },
{
    questionId: 80101,
    category: 'What games will OrcPunks make for the physical world?',
    words: [
      'Board games'
    ]
  },
  {
    questionId: 80102,
    category: 'Type of art used for the OrcPunks design is called',
    words: [
      'Pixel'
    ]
  },
  {
    questionId: 80103,
    category: 'What is the native token of the Orc?',
    words: [
      'ZORG'
    ]
  },
  {
    questionId: 80104,
    category: 'Activity to earn more ZORG coins is called',
    words: [
      'Staking'
    ]
  },
  {
    questionId: 80105,
    category: 'What is the OrcPunks metaverse?',
    words: [
      'Orcverse'
    ]
  },
{
    questionId: 80106,
    category: 'ZORG is the _______ token of the OrcPunks',
    words: [
      'Native'
    ]
  },
{
    questionId: 90101,
    category: 'Makati is divided into how many Barangays?',
    words: [
      'Thirty Three'
    ]
  },
  {
    questionId: 90102,
    category: 'What is the airdrop sent to people with the 5 Maka-Bros?',
    words: [
      'Maka Objects'
    ]
  },
  {
    questionId: 90103,
    category: 'The Maka-Bros brand was made in',
    words: [
      'Italy'
    ]
  },
  {
    questionId: 90104,
    category: 'How many copies does the Maka-Bros chapter have?',
    words: [
      'One thousand'
    ]
  },
  {
    questionId: 90105,
    category: 'The 5th Maka-Bros character in the series is',
    words: [
      'Bat'
    ]
  },
{
    questionId: 90106,
    category: 'The female Maka-Bros character is',
    words: [
      'Wanda'
    ]
  },
{
    questionId: 100101,
    category: 'Doctor who tried to create the perfect Eheadz in his lab is',
    words: [
      'Doctor Gaban'
    ]
  },
  {
    questionId: 100102,
    category: 'What does EME stand for?',
    words: [
      'Elrond Mutilum Element'
    ]
  },
  {
    questionId: 100103,
    category: 'What process did the Eheadz undergo?',
    words: [
      'Mutation'
    ]
  },
  {
    questionId: 100104,
    category: 'What did Dr. Gaban want for the Eheadz?',
    words: [
      'Perfection'
    ]
  },
  {
    questionId: 100105,
    category: 'EME HODLER is an SFT that will benefit who?',
    words: [
      'EHEADZ Believers'
    ]
  },
{
    questionId: 100106,
    category: 'Mutation is the process that the _____ undergo',
    words: [
      'Eheadz'
    ]
  },
{
    questionId: 210101,
    category: 'Effort Economy enables as many ___ opportunities',
    words: [
      'Earning'
    ]
  },
  {
    questionId: 210102,
    category: 'The objective of Effort Economy is ____ ____ adoption',
    words: [
      'Internet Scale'
    ]
  },
  {
    questionId: 210103,
    category: "What is Effort Economy's Learn to Earn app?",
    words: [
      'Rondle'
    ]
  },
  {
    questionId: 210104,
    category: 'Players can earn by participating in different ____ apps',
    words: [
      'Effort to Earn'
    ]
  },
  {
    questionId: 210105,
    category: 'What is the token of Effort Economy?',
    words: [
      'EFFORT Token'
    ]
  },

{
    questionId: 310101,
    category: 'What do you call the token of Elrond Network?',
    words: [
      'EGLD'
    ]
  },
  {
    questionId: 310102,
    category: "What is the race of Elrond's namesake in Lord of the Rings?",
    words: [
      'Elf'
    ]
  },
  {
    questionId: 310103,
    category: 'The first decentralized exchange in the Elrond Network is',
    words: [
      'Maiar Exchange'
    ]
  },
  {
    questionId: 310104,
    category: 'Who is a Co-founder and CEO of the Elrond Network?',
    words: [
      'Beniamin Mincu'
    ]
  },
 {
    questionId: 310105,
    category: 'What is the Elrond Network?',
    words: [
      'Internet Scale Blockchain'
    ]
  },
 {
    questionId: 410101,
    category: 'What is a new type of scam involving cryptocurrency?',
    words: [
      'Rug Pull'
    ]
  },
{
    questionId: 410102,
    category: 'What does NFT stand for?',
    words: [
      'Non fungible token'
    ]
  },
{
    questionId: 410103,
    category: 'What does P2P stand for?',
    words: [
      'Peer to peer'
    ]
  },
{
    questionId: 410104,
    category: 'Public key where you can receive or send cryptocurrency is',
    words: [
      'Wallet Address'
    ]
  },
{
    questionId: 410105,
    category: 'What is a market where prices are expected to rise?',
    words: [
      'Bull Market'
    ]
  },
{
    questionId: 410106,
    category: 'What is a market where prices are expected to decline?',
    words: [
      'Bear Market'
    ]
  },
{
    questionId: 410107,
    category: 'What is the production of cryptocurrency called?',
    words: [
      'Mining'
    ]
  },
{
    questionId: 410108,
    category: 'What do you call tokens that are not Bitcoin?',
    words: [
      'Alt coins'
    ]
  },
{
    questionId: 410109,
    category: 'What does ICO stand for?',
    words: [
      'Initial coin offering'
    ]
  },
{
    questionId: 410110,
    category: 'What do you call creating an NFT?',
    words: [
      'Minting'
    ]
  },
{
    questionId: 410111,
    category: 'What are groups of data within a blockchain called?',
    words: [
      'Blocks'
    ]
  },
{
    questionId: 410112,
    category: 'What instant messaging platform do projects use?',
    words: [
      'Discord'
    ]
  },
{
    questionId: 410113,
    category: 'What is the term used to describe excitement for a project?',
    words: [
      'Hyped'
    ]
  },
{
    questionId: 41014,
    category: 'What is a crowdsourced pool of cryptocurrencies?',
    words: [
      'Liquidity Pool'
    ]
  },
{
    questionId: 41015,
    category: 'A document that shows the aims and strategies of a project',
    words: [
      'White Paper'
    ]
  },
{
    questionId: 41016,
    category: 'Expression of belief an asset is gonna rise greatly in price',
    words: [
      'To the moon'
    ]
  },
{
    questionId: 41017,
    category: "Term for holding an investment when there's pressure to sell",
    words: [
      'Diamond Hands'
    ]
  },
{
    questionId: 41018,
    category: 'A place to store your cryptocurrency holdings is called',
    words: [
      'Wallet'
    ]
  },
{
    questionId: 41019,
    category: 'Social media platform that projects use to build up hype is',
    words: [
      'Twitter'
    ]
  },
{
    questionId: 41020,
    category: 'Concept where people can play games to earn',
    words: [
      'Play to Earn'
    ]
  },
{
    questionId: 41021,
    category: 'Distributed database shared among nodes of a comp network',
    words: [
      'Blockchain'
    ]
  },
{
    questionId: 41022,
    category: 'Validates and finalizes transactions on the Elrond Network',
    words: [
      'Layer One'
    ]
  },
{
    questionId: 41023,
    category: 'A method of making a database more manageable for computers',
    words: [
      'Sharding'
    ]
  },
{
    questionId: 41024,
    category: 'Consensus mechanism used to verify new crypto transactions',
    words: [
      'Proof of Stake'
    ]
  },
{
    questionId: 41025,
    category: 'What does POW stand for?',
    words: [
      'Proof of Work'
    ]
  },
{
    questionId: 41026,
    category: 'Programs on a blockchain that run when conditions are met',
    words: [
      'Smart contract'
    ]
  },
{
    questionId: 41027,
    category: 'Secure method of cryptocurrency completely offline',
    words: [
      'Cold Wallet'
    ]
  },
{
    questionId: 41028,
    category: 'Basic rules that allow data to be shared between computers',
    words: [
      'Protocols'
    ]
  },
{
    questionId: 41029,
    category: 'Term used to refer to cheapest price of NFT in a collection',
    words: [
      'Floor price'
    ]
  },
{
    questionId: 41030,
    category: 'Digital marketplace where you buy and sell cryptocurrency',
    words: [
      'Exchange'
    ]
  },
{
    questionId: 41031,
    category: 'The total number of tokens in a collection or a project',
    words: [
      'Total Supply'
    ]
  },
{
    questionId: 41032,
    category: 'Software-based cryptocurrency wallet connected to Internet',
    words: [
      'Hot Wallet'
    ]
  },
{
    questionId: 41033,
    category: 'The act of sending tokens for free to eligible addresses',
    words: [
      'Airdrop'
    ]
  },
{
    questionId: 41034,
    category: 'What is a computer that connects to a blockchain network?',
    words: [
      'Node'
    ]
  },
{
    questionId: 41035,
    category: 'What refers to people who often make risky and bad bets?',
    words: [
      'Degens'
    ]
  },
{
    questionId: 41036,
    category: 'Fee to pay to the blockchain network for transactions',
    words: [
      'Gas'
    ]
  },
{
    questionId: 41037,
    category: 'PFP is short for what term?',
    words: [
      'Profile Picture'
    ]
  },
{
    questionId: 41038,
    category: 'The cryptocurrency that is pegged to a fiat currency is',
    words: [
      'Stable coin'
    ]
  },
{
    questionId: 41039,
    category: 'Words that are used to access your cryptocurrency wallet',
    words: [
      'Seed Phrase'
    ]
  },
{
    questionId: 41040,
    category: 'Amount of tokens distributed outside the creator contract',
    words: [
      'Circulating supply'
    ]
  },
{
    questionId: 500001,
    category: 'Quezon City is divided into how many Barangays?',
    words: [
      'One Hundred Forty Two'
    ]
  },
{
    questionId: 500002,
    category: 'The current Mayor of Quezon City is',
    words: [
      'Joy Belmonte'
    ]
  },
{
    questionId: 500003,
    category: 'What is the original name of Quezon City?',
    words: [
      'Balintawak City'
    ]
  },
{
    questionId: 500004,
    category: 'The population of Quezon City as of 2022 Census is approximately',
    words: [
      'Three Million'
    ]
  },
{
    questionId: 500005,
    category: 'What is the famous festival in Quezon City?',
    words: [
      'La Loma Lechon Festival'
    ]
  },
{
    questionId: 500006,
    category: 'What is the nickname of Quezon City?',
    words: [
      'The City of Stars'
    ]
  },
{
    questionId: 500007,
    category: 'The present Vice Mayor of Quezon City is',
    words: [
      'Gian Carlo Sotto'
    ]
  },
{
    questionId: 500008,
    category: 'The wealthiest City in the Philippines now is',
    words: [
      'Quezon City'
    ]
  },
{
    questionId: 500009,
    category: 'What are Senior Citizens exempted from in Quezon City?',
    words: [
      'Parking Fee'
    ]
  },
{
    questionId: 500010,
    category: 'The Top 1 NCR Mayor as per latest RPMD survey is',
    words: [
      'Joy Belmonte'
    ]
  }
]



export default dictionary_en
