import React from "react";
import { getIsLoggedIn, DappUI } from "@elrondnetwork/dapp-core";
// import Logo from "../../assets/images/logo/main_logo.png";
import { routes } from "../../constants";
import { getLogo } from "../../utils/utils";

import "./styles.css";

export const UnlockRoute = () => {
  const {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton,
  } = DappUI;

  React.useEffect(() => {
    const isLoggedIn = getIsLoggedIn();
    if (isLoggedIn) {
      console.log("loggg", isLoggedIn);
      window.location.href = routes.home;
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
        maxWidth: "480px",
        margin: "0 auto 0",
        paddingRight: "15px",
        paddingLeft: "15px",
      }}
    >
      <img
        src={getLogo()}
        style={{
          width: 120,
          height: 120,
          objectFit: "cover",
          borderRadius: "50%",
          marginBottom: 40,
          marginTop: 20,
        }}
      />
      <div className="home d-flex flex-fill align-items-center unlock_login_modal">
        <div className="m-auto w-100" data-testid="unlockPage">
          <div className="card my-4 text-center">
            <div className="card-body py-4 px-2 px-sm-2 mx-lg-4">
              <h4 className="mb-4">Login</h4>
              {/* <p className="mb-4">pick a login method</p> */}
              {/* 
              <ExtensionLoginButton
                callbackRoute={routes.home}
                loginButtonText={"Extension"}
              />
              <WebWalletLoginButton
                callbackRoute={routes.home}
                loginButtonText={"Web wallet"}
              />
              <LedgerLoginButton
                loginButtonText={"Ledger"}
                callbackRoute={routes.home}
                className={"test-class_name"}
              /> */}
              <WalletConnectLoginButton
                callbackRoute={routes.maiar}
                loginButtonText={"Maiar"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockRoute;
