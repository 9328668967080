import React, { useEffect, useState } from 'react';
import useStyles from './styles'; 
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import useWindowDimensions from '../../../../utils/windowDimention';
import profile from "../../../../assets/images/common-images/user_placeholder.png";
import Avatar from '@mui/material/Avatar';
// import WebFont from 'webfontloader';
import { border } from '@mui/system';
// import Volume from "./Volumbtn"
import InnerPageHeader from "../../../../components/InnerPageHeader";
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../../../assets/images/logo/tourney_logo.png'
import moment from 'moment';
import { formatNumberWithComma, msToShortenedTimeLeft, getTimeLeft } from '../../../../utils/utils';

import { getLeaderboard } from '../../../../services/ApiService';

const Users = [
    {
        no: 4,
        profile: <Avatar sx={{ width: 30, height: 30 }} />,
        usercode: "RG05688",
        userid: 304
    },
    {
        no: 5,
        profile: <Avatar sx={{ width: 30, height: 30 }} />,
        usercode: "RG05688",
        userid: 304
    },
    {
        no: 6,
        profile: <Avatar sx={{ width: 30, height: 30 }} />,
        usercode: "RG05688",
        userid: 304
    },
    {
        no: 7,
        profile: <Avatar sx={{ width: 30, height: 30 }} />,
        usercode: "RG05688",
        userid: 304
    },
    {
        no: 8,
        profile: <Avatar sx={{ width: 30, height: 30 }} />,
        usercode: "RG05688",
        userid: 304
    },
    {
        no: 9,
        profile: <Avatar sx={{ width: 30, height: 30 }} />,
        usercode: "RG05688",
        userid: 304
    },
    {
        no: 10,
        profile: <Avatar sx={{ width: 30, height: 30 }} />,
        usercode: "RG05688",
        userid: 304
    },
]

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const NUM_ITEMS = 10;

const LeadBoard = (props) => {

    // useEffect(() => {
    //     addFont()
    // }, []);

    // const addFont = () => {
    //     WebFont.load({
    //         google: {
    //             families: ['Droid Sans', 'Chilanka']
    //         }
    //     });
    // }

    // function push() {
    //     navigate(-1);
    // }

    const classes = useStyles();
    const { height, width } = useWindowDimensions();
    const [progress, setProgress] = useState(0);
    const { engagementData } = props;
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [rank1Image, setRank1Image] = useState();
    const [rank1Name, setRank1Name] = useState();
    const [rank1Score, setRank1Score] = useState();
    const [rank2Image, setRank2Image] = useState();
    const [rank2Name, setRank2Name] = useState();
    const [rank2Score, setRank2Score] = useState();
    const [rank3Image, setRank3Image] = useState();
    const [rank3Name, setRank3Name] = useState();
    const [rank3Score, setRank3Score] = useState();
    

    useEffect(() => {
        const timer = setTimeout(() => {
          setTimeElapsed(timeElapsed + 1)
        }, 1000);
      
        return () => clearTimeout(timer);
    });

    useEffect(() => {
        window.addEventListener('touchstart', touchStart);
        window.addEventListener('touchmove', preventTouch, { passive: false });
        return () => {
            window.removeEventListener('touchstart', touchStart);
            window.removeEventListener('touchmove', preventTouch, {
                passive: false,
            });
        };
    }, []);

    const fetchData = async () => {
        let numItems = NUM_ITEMS;
        if (typeof engagementData.topPlayers !== 'undefined' && engagementData.topPlayers > 0) {
            numItems = Math.min(engagementData.topPlayers, NUM_ITEMS);
        }
        try {
          const response = await getLeaderboard({numItems, engagementId: engagementData.newId});
        //   console.log(`response=${JSON.stringify(response)}`)
          if (response) {
            const data = response.data;
            if (data.length > 0) {
              setRank1Image(data[0].avatarBig);
              setRank1Name(data[0].displayName);
              setRank1Score (data[0].bestScore);
            }
            if (data.length > 1) {
              setRank2Image(data[1].avatarBig);
              setRank2Name(data[1].displayName);
              setRank2Score (data[1].bestScore);
            }
            if (data.length > 2) {
              setRank3Image(data[2].avatarBig);
              setRank3Name(data[2].displayName);
              setRank3Score (data[2].bestScore);
            }
            setData(data.slice(3,numItems));
          }
        } catch (e) {
          console.log(e);
        }
    };

    useEffect(() => {
        for (let index = 1; index <= 100; index++) {
            setTimeout(() => {
                setProgress(index);
                if (index === 100) {
                    // var timer = setTimeout(() => {
                    //   navigate(routes.funtyperunner, { state: props });
                    // }, 3500);
                    // i++;
                    // return () => {
                    //   clearTimeout(timer)
                    // }
            }
        }, 1000);
        fetchData();
      }
    }, []);


    const touchStart = (e) => {
        firstClientX = e.touches[0].clientX;
        firstClientY = e.touches[0].clientY;
    };

    const preventTouch = (e) => {
        const minValue = 5; // threshold

        clientX = e.touches[0].clientX - firstClientX;
        clientY = e.touches[0].clientY - firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    };



    return (
        <div className={classes.homeContainer} 
        //style={{ height: height }}
        >
            <div style={{paddingLeft: -20, paddingRight: 20}}>
              <InnerPageHeader
                hasBackBtn
                // hasSoundBtn
                goBackClick={() => {
                  navigate(-1)
                }}
                title={`${process.env.REACT_APP_PREFIX}: ${engagementData.title}`}
              />
            </div>
            <div className={classes.giftBoxContent}>
              <div className={classes.giftBoxSmallImageBox}>
                <img src={Logo} className={classes.giftBoxSmallImage} />
                {/* <svg
                  className={classes.giftBoxSmallImage}
                  version="1.2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 85 134"
                  width="85"
                  height="85"
                >
                  <g id="Folder 1">
                    <path fill="#27a5a4" d="m85 0v134l-43-32l-42 32v-134z" />
                    <path
                      fill="#fee915"
                      d="m63.5 57.1q1.5 0.3 1.5 1.3q0 0.7-0.7 1.4l-9.8 10l2.3 14.2q0 0.2 0 0.6q0 0.6-0.3 1q-0.2 0.4-0.8 0.4q-0.5 0-1.1-0.3l-12.1-6.7l-12.1 6.7q-0.6 0.3-1.1 0.3q-0.6 0-0.9-0.4q-0.3-0.4-0.3-1q0-0.2 0.1-0.6l2.3-14.2l-9.8-10q-0.7-0.8-0.7-1.4q0-1 1.5-1.3l13.6-2l6.1-12.9q0.5-1.2 1.3-1.2q0.8 0 1.3 1.2l6.1 12.9l13.6 2zm-50.5-46.1c0-2.2 1.8-4 4-4c2.2 0 4 1.8 4 4v13c0 2.2-1.8 4-4 4c-2.2 0-4-1.8-4-4zm17 0c0-2.2 1.8-4 4-4c2.2 0 4 1.8 4 4v13c0 2.2-1.8 4-4 4c-2.2 0-4-1.8-4-4zm17 0c0-2.2 1.8-4 4-4c2.2 0 4 1.8 4 4v13c0 2.2-1.8 4-4 4c-2.2 0-4-1.8-4-4zm17 0c0-2.2 1.8-4 4-4c2.2 0 4 1.8 4 4v13c0 2.2-1.8 4-4 4c-2.2 0-4-1.8-4-4z"
                    />
                  </g>
                </svg> */}
              </div>
              <div className={classes.giftBoxImageVideoContent}>
                <h1 className={classes.leadhead}>LEADERBOARD</h1>
                {/* <h3 className={classes.date}>{moment(new Date()).format('MM/DD/YYYY')}</h3>  */}
                <h3 className={classes.date}>ends in {msToShortenedTimeLeft(getTimeLeft(engagementData.joinEndDate) - timeElapsed, 'Expired')}</h3>
                <div className={classes.outer_main}>
                  <div className={classes.innerleft}>
                      <h3 className={classes.innerhead}>Rank 2</h3>
                      <div className={classes.rounded}>
                        <img src={rank2Image ? rank2Image : profile} className={classes.imgrounded} />
                      </div>
                      <div className={classes.name}>
                          {rank2Name}
                      </div>
                      <div className={classes.user}>
                        {rank2Score ? formatNumberWithComma(rank2Score, 0) : ""}
                      </div>
                  </div>

                  <div className={classes.middlebord}>
                      <h3 className={classes.innerhead}>Rank 1</h3>
                      <div>
                          <div className={classes.rounded_mid}>
                              <img src={rank1Image ? rank1Image : profile} className={classes.imgrounded} />
                          </div>
                      </div>
                      <div className={classes.name}>
                          {rank1Name}
                      </div>
                      <div className={classes.user}>
                          {rank1Score ? formatNumberWithComma(rank1Score, 0) : ""}
                      </div>
                  </div>

                  <div className={classes.innerright}>
                      <h3 className={classes.innerhead}>Rank 3</h3>
                      <div className={classes.rounded}>
                        <img src={rank3Image ? rank3Image : profile} className={classes.imgrounded} />
                      </div>
                      <div className={classes.name}>
                          {rank3Name}
                      </div>
                      <div className={classes.user}>
                        {rank3Score ? formatNumberWithComma(rank3Score, 0) : ""}
                      </div>
                  </div>

                </div>
                <div style={{ padding: '0px 10px 10px 10px', marginTop: 5, minHeight: 300}}>
                    {
                        data.map((e, index) => {
                            return (
                                <div key={`profile-1${index}`} className={classes.list}>
                                    <div className={classes.Nom}><p className={classes.number}>{e.rank}</p></div>
                                    <img src={e.avatarBig ? e.avatarBig : profile} className={classes.avt} />
                                    <div className={classes.usercode}><p className={classes.para}>{e.displayName}</p></div>
                                    <div className={classes.userid}><p>{formatNumberWithComma(e.bestScore, 0)}</p></div>
                                </div>
                            )
                        })
                    }
                </div>
              </div>
            </div>
            <div className={classes.customProgress}>
              <div
                className={classes.customProgressBar}
                style={{ width: +progress + "%" }}
              ></div>
              <span className={classes.customProgressText}>{progress}%</span>
            </div>
        </div >
    )
}

export default LeadBoard;