export const routes = {
  homepage: "/",
  login: "/login",
  home: "/home",
  funtyperunner: "/funtyperunner/:id",
  broadcast: "/broadcast",
  boost: "/boost",
  boostDetails: "/boostDetails",
  gameLoader: "/gameloader",
  scratchcardpage: "/scratchcard",
  scratchwinpage: "/scratchwin",
  tips: "/show",
  profile: "/profile",
  notification: '/notification',
  history: '/history',
  winners: '/winners',
  famers: '/famers',
  leaderboard: '/leaderboard',
  unlock: "/unlock",
  maiar: "/maiar",
  hlLeaders: '/hlLeaders',
  collection: '/collection',
  collectionDetails: '/collectionDetails',
  store: '/store',
  storeDetails: '/storeDetails',

  leaderboards: "/leaderboards",
  postLeaderboard: "/postLeaderboard",
  uploadMedia: "/uploadMedia",

  topic: '/topic',
  topic2: '/topic2',
  henyo: '/henyo',
};

export const ids = {
  HofMaxWins: 5,

  FunTypeFamilyFamer: Number(process.env.REACT_APP_FTF_FAMERS),
  FunTypeFamilyTourney: Number(process.env.REACT_APP_FTF_TOURNEYS),
  FunTypeFamilyHighlight: 3,
  FunTypeFamilyRondleTopics: Number(process.env.REACT_APP_FTF_TOPICS),

  //transactionType
  txnTypeJackpot: 3,
  txnTypeBattleFee: 5,
  txnTypeBattleWin: 6,
  txnTypeBattleDraw: 7,
  txnTypeBattleCancel: 8,
  txnTypeTourneyFee: 9,
  txtTypeWelcomeTreat: 17,
  txnTypeBoughtCharm: 20,
  txnTypeBrandFamer: 21,
  txnTypeBrandHOF: 22,
  txnTypeBrandJackpot: 23,
  txnTypeBrandNineBoxes: 24,

  gameTypeFunType: 0,
  gameTypeWordle: 1,
  gameTypeFillInTheBlanks: 2,
  gameType2048: 3,
  gameTypeMultipleChoice: 4,
  gameTypeWheel: 5,
  gameTypeSlotMachine: 6,
  gameTypeLotto: 7,
  gameTypeCubeRun: 8,
  gameTypePinoyHenyo: 9, //this that multiple choice
  gameTypePinoyHenyo2: 10, //this that know with keyboard
  gameTypePinoyHenyo3: 11, //clue then keyboard

  benggaStoreId: 1,

  storeCategoryGC: 2,
  storeCategoryMeds: 7,
  storeCategoryGroceries: 8,

  cityMakati: 1,
  cityQuezon: 2,
}