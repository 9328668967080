import React from 'react';
import useStyles from './styles';
import TipsIcon from '../../../assets/images/more-images/tips.png';
import ReplayIcon from '../../../assets/images/postloader/postloader_repeat.png';

const Footer = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.mainfooter} >
            <div className={classes.footer}>
                <div><img src={TipsIcon} className={classes.footericn} onClick={() => props.showTips()} /></div>
                {!props.hideRepeat &&
                    <div><img src={ReplayIcon} className={classes.footericn}  onClick={() => props.onRepeat()} /></div>
                }
            </div>
        </div>
    )
}


export default Footer